import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";
import classNames from 'classnames'
import ScanQR from "./ScanQR";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch'
    },
    header: {
      marginBottom: 10,
      marginTop: 10,
    },
    blue: {
        backgroundColor: 'blue'
    },
    green: {
        backgroundColor: 'green'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    registerArea: {
        marginTop: 30,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center'
    },
    modeButton: {
        textAlign: 'center',
        color: 'white',
        height: 50
    },
    addButton: {
        textAlign: 'center',
        color: 'white',
        height: 100,
        backgroundColor: 'grey',
        fontSize: 20,
        borderRadius: 0
    }
});

const AddMode = (props) => {
    const { classes, customerMode, onAdd, onChangeMode, slotsAvailable, capacity, invitations, onInviteScan } = props;
    const themeStyle = customerMode ? classes.green : classes.blue;
    const heading = customerMode ? <Typography variant="h4">Customer</Typography> :
        <Typography variant="h4">Slot</Typography>;
    const readQRCode = customerMode ? <ScanQR invitations={invitations} onInviteScan={onInviteScan} /> : null;
    const onAddOne = () => onAdd({value: 1});
    const onAddTwo = () => onAdd({value: 2});
    const onAddThree = () => onAdd({value: 3});
    const onAddFour = () => onAdd({value: 4});
    const isAddOne = !customerMode ? slotsAvailable + 1 <= capacity : slotsAvailable - 1 >= 0;
    const isAddTwo = !customerMode ? slotsAvailable + 2 <= capacity : slotsAvailable - 2 >= 0;
    const isAddThree = !customerMode ? slotsAvailable + 3 <= capacity : slotsAvailable - 3 >= 0;
    const isAddFour = !customerMode ? slotsAvailable + 4 <= capacity : slotsAvailable - 4 >= 0;
    return (
        <div className={classes.root}>
            <Paper className={classNames(classes.paper, themeStyle)}>
                <Typography variant="h5">Add</Typography>
                {heading}
                {readQRCode}
                <div className={classNames(classes.row, classes.header)}>
                    <Button
                        onClick={onChangeMode}
                        fullWidth
                        color="primary"
                        className={classes.modeButton}
                    >
                        Customer
                    </Button>
                    <Button
                        onClick={onChangeMode}
                        fullWidth
                        color="primary"
                        className={classes.modeButton}
                    >
                        Slot
                    </Button>
                </div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <Button
                            onClick={onAddOne}
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.addButton}
                            disabled={!isAddOne}
                        >
                            +1
                        </Button>
                        <Button
                            onClick={onAddThree}
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.addButton}
                            disabled={!isAddThree}
                        >
                            +3
                        </Button>
                    </div>
                    <div className={classes.column}>
                        <Button
                            onClick={onAddTwo}
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.addButton}
                            disabled={!isAddTwo}
                        >
                            +2
                        </Button>
                        <Button
                            onClick={onAddFour}
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.addButton}
                            disabled={!isAddFour}
                        >
                            +4
                        </Button>
                    </div>
                </div>
            </Paper>
        </div>

    )
};

AddMode.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddMode);
