import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        tileRoot: {
            padding: 15,
        },
        paper: {
            width: '100%',
            height: '100%',
            flexGrow: 1,
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        gridContainer: {
            height: '100%',
            margin: 1,
        },
        gridItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
        }
    }),
);

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
const getYear = (year: any) => Object.keys(year);

const getMonth = (month: any) => Object.keys(month);

const getDay = (day: any) => Object.keys(day).length;

const getTotalCityReqs = (set: any) => {
    const arr: JSX.Element[] = [];
    Object.keys(set).forEach(year => {
        let total = 0;
        getYear(set[year]).forEach(month => {
            getMonth(set[year][month]).forEach(day => {
                total += getDay(set[year][month][day])
            })
        });
        arr.push(<div>{year} - {total}</div>)
    });
    return arr;
}

const ServicesGridList = (props: any) => {
    const classes = useStyles();
    const { services } = props;
    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={2.5}>
                {services.map((service: any) =>
                    Object.keys(service).map(name =>
                        <GridListTile
                            key={name}
                            classes={{tile: classes.tileRoot}}
                            cols={1.5}
                            >                                    <Paper className={classes.paper} elevation={3}>

                        <Grid
                                    direction="row"
                                    alignItems="stretch"
                                    alignContent="stretch"
                                    container
                                    className={classes.gridContainer}
                                >
                                        <Grid
                                            item xs={12} sm={6}
                                            className={classes.gridItem}
                                        >
                                            <Typography variant={'h6'}>{name.replace(/[_]+/g, ' ').toLowerCase()}</Typography>
                                        </Grid>
                                        <Grid
                                            item xs={12} sm={6}
                                            className={classes.gridItem}
                                        >
                                            <Typography variant={'body1'}>{getTotalCityReqs(service[name])}</Typography>
                                        </Grid>

                                </Grid>
                            </Paper>

                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

export default ServicesGridList
