// ACTIONS
import {actionTypes} from "./store";

//appState
export const setDecisionTree = (tree) => ({
        type: actionTypes.SET_TREE,
        payload: tree
    });

export const setAssets = (assets) => ({
        type: actionTypes.SET_ASSETS,
        payload: assets
    });

export const upsertReport = (probability, answer) => ({
        type: actionTypes.UPSERT_REPORT,
        payload: {
            probability,
            answer
        }
    });

export const resetApp =() => {
    return { type: actionTypes.RESET }
};

export const acceptTerms = () =>
     ({ type: actionTypes.ACCEPT_TERMS });

export const authSuccess = (user) => ({
        type: actionTypes.AUTHENTICATION_SUCCESS,
        payload: user
    });

export const logedOut = (user) => ({
        type: actionTypes.LOGGED_OUT
    });

export const updateLine = (line) => ({
        type: actionTypes.UPDATE_LINE,
        payload: line
    });

export const updateUserLines = (line) => ({
        type: actionTypes.UPDATE_USER_LINES,
        payload: line
    });

//admin


export const closeDashboardMenu = () =>
    ({ type: actionTypes.MENU_CLOSED });

export const openDashboardMenu = () =>
    ({ type: actionTypes.MENU_OPEN });

export const openCreateTreeDialog = () =>
    ({ type: actionTypes.OPEN_CREATE_TREE_DIALOG });

export const openSwapAssetsDialog = () =>
    ({ type: actionTypes.OPEN_SWAP_ASSETS_DIALOG });

export const openEditTreeDialog = tree =>
    ({
        type: actionTypes.OPEN_EDIT_TREE_DIALOG,
        payload: tree
    });

export const openEditQuestionDialog = question =>
    ({
        type: actionTypes.OPEN_EDIT_QUESTION_DIALOG,
        payload: question
    });

export const openEditAssetDialog = asset =>
    ({
        type: actionTypes.OPEN_EDIT_ASSET_DIALOG,
        payload: asset
    });

export const closeEditAssetDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_ASSET_DIALOG });

export const closeSwapAssetsDialog = () =>
    ({ type: actionTypes.CLOSE_SWAP_ASSETS_DIALOG });

export const openCreateQuestionDialog = () =>
    ({ type: actionTypes.OPEN_CREATE_QUESTION_DIALOG });

export const closeCreateTreeDialog = () =>
    ({ type: actionTypes.CLOSE_CREATE_TREE_DIALOG });

export const closeEditTreeDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_TREE_DIALOG });

export const closeEditQuestionDialog = () =>
    ({ type: actionTypes.CLOSE_EDIT_QUESTION_DIALOG });

export const closeCreateQuestionDialog = () =>
    ({ type: actionTypes.CLOSE_CREATE_QUESTION_DIALOG });

export const createNewTree = () =>
    ({ type: actionTypes.CREATE_NEW_TREE });

export const createNewQuestion = () =>
    ({ type: actionTypes.CREATE_NEW_QUESTION });

export const tabsChanged = (event, val) =>
    ({ type: actionTypes.TABS_CHANDED, payload: val });


//tastes


export const addTasteToUserAccount = (item) =>
    ({ type: actionTypes.TASTE_ADDED, payload: item });

//aiNetwork
export const setAIServerToken = (token) =>
    ({ type: actionTypes.TOKEN_RECEIVED, payload: token });
/*
export const sendTasteToAI = ({userID, taste}) => {
    return (dispatch, getState) => {
        const state = getState();
        const { token } = state.aiNetwork;
        console.log(token)
        dispatch({type: actionTypes.REPORT_SENT});
        axios.defaults.withCredentials = true;
        axios({
            method: 'post',
            url: 'http://localhost:8080/api',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            data: {
                userID: userID,
                taste
            }
        }).then( result => {
            console.log(result);
        });
    }
}
*/
