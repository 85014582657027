import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Formik} from "formik";
import red from '@material-ui/core/colors/red';
import { authSuccess } from '../../store/actions';
import {connect} from "react-redux";
import { APP_CONSTS } from '../share/variables';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    register: {
        marginTop: 30,
        width: '100%'
    }

});

class Signup extends Component {
    render()
    {
        const { classes, firebase } = this.props;
        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <PersonAddIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Formik
                        initialValues={{email: '', password: '', confirmedPassword: '', errorMessage: ''}}
                        validate={values => {
                            let errors = {};
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address';
                            }
                            if (!values.password) {
                                errors.password = 'Required';
                            } else if (values.password.length < APP_CONSTS.PASSWORD_LENGTH) {
                                errors.password = 'Passwords length must be equal or greater than ' + APP_CONSTS.PASSWORD_LENGTH;
                            } else if (values.password !== values.confirmedPassword) {
                                errors.confirmedPassword = 'Passwords must match';
                            } else if (!values.type) {
                                errors.type = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            firebase
                                .doCreateUserWithEmailAndPassword(values.email, values.password)
                                .then(authUser => {
                                    if (authUser.user.uid) {
                                        const userProfile = {
                                            uid: authUser.user.uid,
                                            email: authUser.user.email,
                                            isEmailVerified: authUser.user.emailVerified,
                                            type: values.type,
                                        };
                                        if (values.type === 'provider') {
                                            userProfile.line = {
                                                capacity: 50,
                                                radius: 3,
                                                enterTime: 15,
                                                maxPeople: 2,
                                                location: '',
                                                slots: 0,
                                                status: 'in-active',
                                                lineName: '',
                                                logoUrl: ''
                                            };
                                            if (navigator.geolocation) {
                                                navigator.geolocation.getCurrentPosition((position) => {
                                                    userProfile.line.location = `${position.coords.latitude},${position.coords.longitude}`
                                                });
                                            }
                                        }
                                        if (values.type === 'customer') {
                                            userProfile.lines = []
                                        }
                                        firebase.createUserProfile(userProfile)
                                            .then(done => {
                                                this.props.authSuccess(userProfile);
                                                this.props.history.push('/user/home');
                                                setSubmitting(false);

                                        })
                                            .catch(error => {
                                                console.log(error)
                                                setSubmitting(false);
                                                setErrors({email: true})
                                        });

                                    }
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({email: error.message})
                                });
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="email">Email Address</InputLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            autoComplete='off'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            autoFocus
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Are you...</FormLabel>
                                        <RadioGroup aria-label="gender" name="type" value={values.type} onChange={handleChange}>
                                            <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                                            <FormControlLabel value="provider" control={<Radio />} label="Provider" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input
                                            autoComplete='off'
                                            name="password"
                                            type="password"
                                            id="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="confirmedPassword">Confirm Password</InputLabel>
                                        <Input
                                            autoComplete='off'
                                            name="confirmedPassword"
                                            type="password"
                                            id="confirmedPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confirmedPassword}
                                        />
                                    </FormControl>
                                    {errors.email && touched.email && errors.email &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        {typeof errors.email === 'string' && errors.email || 'Authentication Failed'}
                                    </Typography>
                                    }
                                    {errors.password && touched.password && errors.password &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        {errors.password}
                                    </Typography>
                                    }
                                    {errors.confirmedPassword && touched.confirmedPassword && errors.confirmedPassword &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        {errors.confirmedPassword}
                                    </Typography>
                                    }
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Create Account
                                    </Button>
                                </form>
                            )}
                    </Formik>
                </Paper>
            </main>
        );
    }
}

Signup.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { user } = state.user;
    return { user}
};


export default connect(mapStateToProps, {authSuccess})(withStyles(styles)(Signup));
