import React from 'react';
import Signup from '../Auth/Signup';

import  { FirebaseContext } from '../Firebase';

const CreateAccount = (props) => (
    <FirebaseContext.Consumer>
        {firebase => (
            <div>
                <Signup {...props} firebase={firebase}/>
            </div>)
        }
    </FirebaseContext.Consumer>
);

export default CreateAccount;
