import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from '@material-ui/core/colors/red';
import { authSuccess } from '../../store/actions';
import {connect} from "react-redux";
import { Link } from 'react-router-dom'


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    registerArea: {
        marginTop: 30,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center'
    },
    registerButton: {
        textAlign: 'center'
    }

});

const getKeyValue = pair => pair.split('=');
const trimFirst = str => str.substr(1);
const getPairs = str => str.split('&');
const constructObj = pairs => {
    const obj = {};
    pairs.forEach(pair => {
        const [key, value] = getKeyValue(pair);
        obj[key] = value;
    });
    return obj;
};
const validateObj = maybeObj => {
    let isValid = true;
    const validKeys = ['mode', 'oobCode', 'apiKey', 'lang'];
    validKeys.forEach(key => {
        if (!maybeObj.hasOwnProperty(key)) {
            isValid = false;
        }
    });
    if (isValid) {
        isValid = maybeObj['mode'] === 'verifyEmail'
    }

    return isValid
};

const VerifyEmail = (props) => {
        const { classes, firebase } = props;
        const [isValidUrl, setIsValidUrl] = React.useState(undefined);
        const [hasResponse, setResponse] = React.useState(undefined);
        const [isVerified, setVerified] = React.useState(undefined);
        const uri = window.location.search;
        React.useEffect(() => {
            const maybeObj = constructObj(getPairs(trimFirst(uri)));
            console.log('maybeObj', maybeObj)
            if (validateObj(maybeObj)) {
                setIsValidUrl(true);
                firebase.verifyEmail(maybeObj, setResponse)
            } else {
                setIsValidUrl(false)
            }
        }, []);

        React.useEffect(() => {
            if (hasResponse) {
                if (hasResponse.status === 'success') {
                    setVerified(true)
                } else {
                    setVerified(false)
                }
            }
        }, [hasResponse]);

        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Email Verification
                    </Typography>
                    {!isValidUrl &&
                    <Typography component="h1" variant="h5">
                        Invalid Link. Please, verify your email again.
                    </Typography>
                    }
                    {!isVerified &&
                    <React.Fragment>
                        <Typography component="h1" variant="h5">
                            Invalid Link
                        </Typography>
                        <Typography component="h1" variant="h6">
                            Please, verify your email again.
                        </Typography>
                        <Typography variant="body1">
                            {hasResponse?.res?.message}
                        </Typography>

                    </React.Fragment>
                    }
                    {isVerified &&
                    <React.Fragment>
                        <Typography component="h1" variant="h5">
                            Your Email has been verified.
                        </Typography>
                        < Button
                            component={Link}
                            to={'/login'}
                            fullWidth
                            color="primary"
                            className={classes.registerButton}
                            >
                            Continue to Login
                        </Button>
                    </React.Fragment>
                    }
                </Paper>
            </main>
        );
};

VerifyEmail.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { user } = state.user;
    return { user}
};


export default connect(mapStateToProps, {authSuccess})(withStyles(styles)(VerifyEmail));
