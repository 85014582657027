import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import withWidth from '@material-ui/core/withWidth';

import {connect} from "react-redux";
import add_new from '../../assets/images/add_new.png'
import {Link, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Formik} from "formik";
import InsightChart from "./InsightChart";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ServicesGridList from "./ServicesGridList";
import ProviderContent from "./ProviderContent";
import {updateLine, updateUserLines} from "../../store/actions";
import CustomerContent from "./CustomerContent";

const MAP_VIEW_KEY = 'key=AIzaSyByvhr0tZOYKdW1h6zDmt2u6sJDd6U70nM';
const MAP_VIEW_URL = 'https://maps.googleapis.com/maps/api/streetview';
const SIZE = 'size=400x200';
const LOCATION = 'location=';
const POSITION = 'fov=80&heading=70&pitch=0';
const SOURCE = 'source=outdoor';
const RADIUS = 'radius=100';
const MAP_STATIC_KEY = 'key=AIzaSyDkkCKFgPe99TSv5zAZ3-wXcMMFDriMOfA';
const MAP_STATIC_URL = 'https://maps.googleapis.com/maps/api/staticmap';
const MAP_CENTER = '?center=';
const MAP_ZOOM = 'zoom=16';
const MAP_TYPE = 'maptype=roadmap';
const MAP_MARKER = 'markers=color:blue|label:House|size:mid|';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginRight: 10,
        marginLeft: 10,
        marginTop: 20,
        elevation: 0,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    streetTitle: {
      marginRight: 5,
    },
    filter: {
        padding: 10,
    },
    gridListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'scroll',
        width: '100%',
        margin: 10,
    },
    paper: {
        width: '100%',
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        width: 900

    },
    [theme.breakpoints.down('sm')]: {
        cols: 12,
        tile: 1
    },
    [theme.breakpoints.up('md')]: {
        cols: 12,
        tile: 2
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    image: {
        maxHeight: '50%',
        objectFit: 'contain',
        opacity: 0.5
    },
    imageAddButton: {
        height: '100%',
    },
    imageAdd: {
        width: 30,
        objectFit: 'contain',
        opacity: 0.5
    },
    tileContainer: {
        backgroundColor: theme.palette.tertiary.light,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '20%'
    },
    tileTitle: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 20,
        color: theme.palette.primary.dark,
        fontWeight: 600,
    },
    submit:{
        margin: 10,
    },
    textField: {
        marginRight: 10,
    },
    houseView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

function ContentArea(props) {
    const { classes, firebase, user: { line, type, uid, userLines }, updateLine, updateUserLines} = props;
    const [lines, setLines] = React.useState([]);
    useEffect( () => {
        firebase.loadLines(setLines);
    }, []);

    const callBack = (message) => {
        const {line, res} = message;
        if (res.type === 'success') {
            if (type === 'provider') {
                updateLine(line);
            } else {
                updateUserLines(line)
            }
        }
    };
    return (
        <div className={classes.root}>
            {type === 'customer' &&
                <CustomerContent callback={callBack} firebase={firebase} lines={lines} userLines={userLines} userType={type} userId={uid}/>
            }
            {type === 'provider' &&
                <ProviderContent callback={callBack} firebase={firebase} lines={lines} line={line} userType={type} userId={uid}/>
            }
        </div>
    );
}

ContentArea.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {user} = state.user;
    return {user}
};

export default connect(mapStateToProps, { updateLine, updateUserLines })(withStyles(styles)(withWidth()(ContentArea)));
