import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import PropTypes from "prop-types";
import image from '../../assets/images/sample-logo.png'

const ImagePreview = (props) => {
    const { file, styles } = props;
    const [loading, setLoading] = React.useState(false);
    const [preview, setPreview] = React.useState(undefined);

    React.useEffect(() => {
        if (file && typeof file !== 'string') {
            setLoading(true);
            let reader = new FileReader();

            reader.onloadend = () => {
                setLoading(false);
                setPreview(reader.result)
            };

            reader.readAsDataURL(file);
        }
    }, [file]);

        if (loading) { return <p>loading...</p>; }

        return (
            <CardMedia
                className={styles}
                image={preview || image}
                title={`Uploaded file: ${file ? file.name : 'sample'}`}
            />);
    }

ImagePreview.defaultProps = {
    styles: PropTypes.string.isRequired,
    file: null,
};

ImagePreview.propTypes = {
    styles: PropTypes.string.isRequired,
    file: PropTypes.object,
};

export default ImagePreview;
