import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from '@material-ui/core/colors/red';
import { logedOut } from '../../store/actions';
import {connect} from "react-redux";
import grey from '@material-ui/core/colors/grey';
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/Info';
import CircularProgress from '@material-ui/core/CircularProgress';
import Payment from "../webUI/Payment";
import clsx from "clsx";


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    notVerified: {
        backgroundColor: grey[300],
        color: grey[500],
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    register: {
        marginTop: 30,
        width: '100%'
    },
});

class Subscription extends Component {
    state = {
        error: null,
        profile: null,
        emailVerified: null,
        sendingEmail: false,
        deletingSubscription: false
    };

    componentDidMount() {
        this.loadUserProfile();
    }

    getStripe = () => {
        const { user } = this.props;
        // eslint-disable-next-line no-undef
        const stripe = Stripe('pk_live_X4kief21Zqesk2pZMY5e9ILA00rn3ZvB1i');

        stripe.redirectToCheckout({
            items: [{sku: 'sku_GlcAKlw8AeOR6b', quantity: 1}],

            // Do not rely on the redirect to the successUrl for fulfilling
            // purchases, customers may not always reach the success_url after
            // a successful payment.
            // Instead use one of the strategies described in
            // https://stripe.com/docs/payments/checkout/fulfillment
            successUrl: `https://${process.env.REACT_APP_LOCAL_HOST}/success?session_id={CHECKOUT_SESSION_ID}`,
            cancelUrl: `https://${process.env.REACT_APP_LOCAL_HOST}/canceled`,
            customerEmail: user.email,
        })
        .then(function (result) {
            if (result.error) {
                this.setState({error: result.error.message});
            }
        });
    };

    resendVerificationEmail = () => {
        const { firebase, user } = this.props;

        this.setState({sendingEmail: true})
        firebase.resendUserEmailVerification().then(res => {
            this.setState({sendingEmail: false})
        })
    };

    loadUserProfile = () => {
        const { firebase, user } = this.props;
        const emailVerified = firebase.hasUserEmailVerified();
        this.setState({emailVerified});
        firebase.loadUserProfile(user.uid, (profile) => {
            this.setState({profile});
        })
    };

    render()
    {
        const { classes } = this.props;
        const { profile } = this.state;
        return (
            <main className={classes.main}>
                {profile &&
                <Grid
                    direction="column"
                    justify="center"
                    container
                    spacing={3}
                >
                    <Grid item xs={12} stretch>
                        <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                {profile.email.charAt(0).toUpperCase()}
                            </Avatar>
                            <Typography>Hello {profile.email}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} stretch>
                        {profile && profile.payments && profile.payments.map(payment =>
                        <Payment payment={payment} />)
                        }
                        <Typography variant="caption">if you cannot see your payment try to sign out and sign in to your account again.</Typography>
                    </Grid>
                </Grid>
                }
                {!this.state.emailVerified &&
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <InfoIcon />
                    </Avatar>
                    <Typography variant="h5">
                        You need to verify your email first.
                    </Typography>
                    {this.state.sendingEmail && <CircularProgress />}
                    <div>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.resendVerificationEmail}
                        >
                            re-send verification email
                        </Button>
                    </div>
                </Paper>
                }
                <Paper className={clsx(classes.paper, !this.state.emailVerified && classes.notVerified)}>
                    <Avatar className={clsx(classes.avatar, !this.state.emailVerified && classes.notVerified)}>
                        <CardMembershipIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" className={clsx( !this.state.emailVerified && classes.notVerified)}>
                        You will be redirected to Stripe payment solution
                    </Typography>

                    <div>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={!this.state.emailVerified}
                            color="primary"
                            className={classes.submit}
                            onClick={this.getStripe}
                        >
                            Buy a 7-day access
                        </Button>
                    </div>
                </Paper>
            </main>
        );
    }
}

Subscription.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { user } = state.user;
    return { user}
};


export default connect(mapStateToProps, {logedOut})(withStyles(styles)(Subscription));
