import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    registerArea: {
        marginTop: 30,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center'
    },
    registerButton: {
        textAlign: 'center'
    }
});

const LineActions = (props) => {
    const { classes, onStatusUpdate, onManageCustomers } = props;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Line Actions</Typography>
                <div className={classes.row}>
                    <Button
                        onClick={() => onStatusUpdate({status: 'active'})}
                        fullWidth
                        color="primary"
                        className={classes.registerButton}
                    >
                        Start Line
                    </Button>
                    <Button
                        onClick={() => onStatusUpdate({status: 'freez'})}
                        fullWidth
                        color="primary"
                        className={classes.registerButton}
                    >
                        Freez/Unfreez Line
                    </Button>
                    <Button
                        onClick={() => onStatusUpdate({status: 'in-active'})}
                        fullWidth
                        color="primary"
                        className={classes.registerButton}
                    >
                        Stop Line
                    </Button>
                </div>
                <Button
                    onClick={onManageCustomers}
                    fullWidth
                    color="primary"
                    className={classes.registerButton}
                >
                    Manage Customers
                </Button>
            </Paper>
        </div>

    )
};

LineActions.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(LineActions);
