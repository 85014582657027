import React from 'react';
import ResetRequest from '../Auth/ResetRequest';

import  { FirebaseContext } from '../Firebase';

const Reset = (props) => (
    <FirebaseContext.Consumer>
        {firebase => (
            <div>
                <ResetRequest {...props} firebase={firebase}/>
            </div>)
        }
    </FirebaseContext.Consumer>
);

export default Reset;
