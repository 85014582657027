import React from 'react';
import PropTypes, {object} from "prop-types";
import {DialogContent, withStyles} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/sample-logo.png';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import QRCode from 'qrcode.react'
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";

const styles = theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
        minWidth: 260,
    },
    dialog: {
        margin: 10,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    expired: {
        color: 'red',
        fontSize: 14,
        textAlign: 'center',
    }
});


const UserLineTile = (props) => {
    const { classes, line, onLeave, checkInvite } = props;
    const [invite, setInvite] = React.useState(null);
    const [isShowingInvite, openInvite] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false)

    //const loadInvite = checkInvite(setInvite);

    React.useEffect(() => {
        checkInvite(setInvite);
    }, []);
    if (!line) {
        return null
    }
    const members = line && line.members && Object.keys(line.members).length || 0;
    const currentTime = moment();
    const inviteTime = moment(invite?.inviteObj?.expiredOn, 'x');
    const expired = moment(currentTime).isAfter(inviteTime);
    return(
        <React.Fragment>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={line.lineLogo || logo}
                        title={line.title || 'Sample Line'}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {line.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            In line: {members}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => onLeave({lineId: line.id})}
                    >
                        Leave Line
                    </Button>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => setOpenInfo(true)}
                        >
                        More Info
                    </Button>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        disabled={!invite}
                        onClick={() => openInvite(true)}
                    >
                        Show Invite
                    </Button>
                </CardActions>
            </Card>
            <Dialog onClose={() => openInvite(false)} open={isShowingInvite}>
                <DialogTitle id="simple-dialog-title">Show QR-Code at the Entrance</DialogTitle>
                <DialogContent className={classes.dialog} dividers>
                    { !expired && <QRCode value={invite && invite.inviteObj && invite.inviteObj.id || 'test'} /> }
                    { expired && <div className={classes.expired}> Your invitation has expired. You need to join the line again.</div> }
                </DialogContent>
                <DialogActions>
                    <Typography variant="caption" color="secondary">
                        The QR-Code expires at <strong>{moment(invite?.inviteObj?.expiredOn, 'x').format('hh:mm')}</strong>
                    </Typography>
                    <Button autoFocus onClick={() => openInvite(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={() => setOpenInfo(false)} open={openInfo}>
                <DialogTitle id="simple-dialog-title">Line Info</DialogTitle>
                <DialogContent className={classes.dialog} dividers>
                    {line && renderLine(line)}
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => setOpenInfo(false)}
                        color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

UserLineTile.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(UserLineTile);

const renderLine = line => {
    const row = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    };
    return (
        <React.Fragment>
            <div style={row}>
                <Typography variant="body1">Store Name: </Typography>
                <Typography variant="h6">{line.lineName}</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Max distance to get in line: </Typography>
                <Typography variant="h6">{line.radius} metres</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Time to enter after receiving invitation: </Typography>
                <Typography variant="h6">{line.enterTime} minutes</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">People allowed to enter in one spot: </Typography>
                <Typography variant="h6">{line.maxPeople} people</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Store capacity: </Typography>
                <Typography variant="h6">{line.capacity} people</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Store location: </Typography>
                <Typography variant="h6">{line.location}</Typography>
            </div>
        </React.Fragment>
    );
};
