import React from 'react';
import './styles/privacyPolicyStyles.css';
import {Typography} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';


export default (props) => {
    return (
    <div className="Privacy-Page">
        <section className="Page-Section">
            <article className="Page-Article">
                <Typography variant="h2" paragraph>
                    Privacy Policy
                </Typography>
                <Typography variant="caption" paragraph>
                    Last updated: September 17, 2020
                </Typography>
                <Typography variant="body1" paragraph>
                    LineThru ("us", "we", or "our") operates https://linethru.com and LineThru Android app (the "App").
                    This page informs you of our policies regarding the collection, use and disclosure of Personal
                    Information we receive from users of the App.

                    We use your Personal Information only for providing and improving the App. By using the App,
                    you agree to the collection and use of information in accordance with this policy.
                </Typography>
                    <Typography variant="h5" paragraph>
                        Information Collection And Use
                    </Typography>
                <Typography variant="body1" paragraph>
                    While using our App, we may ask you to provide us with certain personally identifiable information
                    that can be used to contact or identify you. Personally identifiable information may include, but
                    is not limited to your email ("Personal Information").
                    The App requires your device location to provide its service ("Location Information"). We store the
                    retrieved longitude and latitude and compute the distance between your device location and the
                    location of the line provider.
                    We do not share Personal Information and Location Information with any third parties.
                </Typography>
                <Typography variant="h5" paragraph>
                    Log Data
                </Typography>
                <Typography variant="body1" paragraph>
                    Like many App operators, we collect information that your browser sends whenever you visit our App ("Log Data").

                    This Log Data may include information such as your computer's Internet Protocol ("IP") address,
                    browser type, browser version, the pages of our App that you visit, the time and date of your visit,
                    the time spent on those pages and other statistics.

                    In addition, we may use third party services such as Google Analytics that collect, monitor and
                    analyze this App.
                </Typography>
                <Typography variant="h5" paragraph>
                    Communications
                </Typography>
                <Typography variant="body1" paragraph>
                    We may use your Personal Information to contact you with newsletters about the app itself.
                </Typography>
                <Typography variant="h5" paragraph>
                    Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                    Cookies are files with a small amount of data, which may include an anonymous unique identifier.
                    Cookies are sent to your browser from a web App and stored on your computer's hard drive.

                    Like many Apps, we use "cookies" to collect information. You can instruct your browser to refuse all
                    cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may
                    not be able to use some portions of our App.
                </Typography>
                <Typography variant="h5" paragraph>
                    Security
                </Typography>
                <Typography variant="body1" paragraph>

                    The security of your Personal Information is important to us, but remember that no method of
                    transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to
                    use commercially acceptable means to protect your Personal Information, we cannot guarantee its
                    absolute security.
                </Typography>
                <Typography variant="h5" paragraph>
                    Changes To This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    This Privacy Policy is effective as of September 17, 2020 and will remain in effect except with
                    respect to any changes in its provisions in the future, which will be in effect immediately after
                    being posted on this page.

                    We reserve the right to update or change our Privacy Policy at any time and you should check this
                    Privacy Policy periodically. Your continued use of the Service after we post any modifications to
                    the Privacy Policy on this page will constitute your acknowledgment of the modifications and your
                    consent to abide and be bound by the modified Privacy Policy.

                    If we make any material changes to this Privacy Policy, we will notify you either through the email
                    address you have provided us, or by placing a prominent notice on our App.

                </Typography>
                <Typography variant="h5" paragraph>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>

                    If you have any questions about this Privacy Policy, please contact us: inquires@linethru.com.

                </Typography>
            </article>
        </section>
    </div>
  );
};




