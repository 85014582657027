import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LineTile from "./LineTile";
import GridList from "@material-ui/core/GridList";
import UserLineTile from "./UserLineTile";
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    lineContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    }
});

const actionProto = (a, c) => fn => b => fn({...a, ...b}, c);
const actionProto2 = a => (fn, b) => c => fn({...a, ...b}, c);

const CustomerContent = (props) => {
    const {
        classes,
        firebase,
        lines,
        userId,
        userLines,
        callback
    } = props;
    const userLocation = 'testLocation';
    const action = actionProto({uid: userId, userLocation}, callback);
    const checkAction = actionProto2({userId});

    const [value, setValue] = React.useState(0);
    const [location, setLocation] = React.useState({});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        getUserLocation(setLocation)
    });

    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label={<Badge color="secondary" variant="dot">My Line</Badge>} />
                <Tab label="All Lines" />
            </Tabs>
            <TabPanel value={value} index={0}>
                {userLines && userLines.map(line =>
                    <UserLineTile
                        line={lines.find(lineObj => lineObj.id === line.id)}
                        joined={userLines[line]}
                        checkInvite={checkAction(firebase.checkUserInvitation, {lineId: line.id})}
                        onLeave={action(firebase.leaveLine)}
                    />
                        )
                }
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.lineContainer}>
                {lines.map(line => <LineTile line={line} onJoin={action(firebase.joinLine)} location={location}/>)}
            </TabPanel>
        </div>

    )
};


CustomerContent.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomerContent);

function getUserLocation(callback) {
    if(!navigator.geolocation) {
        callback(null);
    } else {
        navigator.geolocation.getCurrentPosition((position) => {
            const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            };
            callback(location);
        }, (error) => {
            console.log('error', error);
            callback(null);
        });
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
