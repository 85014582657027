import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import LineSettings from "./LineSettings";
import LineStatus from "./LineStatus";
import LineActions from "./LineActions";
import ManageCustomers from "./ManageCustomers";
import moment from "moment";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginBottom: 40
    },
});

const actionProto = (a, c) => fn => b => fn({...a, ...b}, c);
const actionProto2 = a => fn => b => fn({...a, ...b});

const isNotExpired = (expiredOn, minutes) => {
    const currentTime = moment();
    const expiredTime = moment(expiredOn, 'x');
    const allowedTime = expiredTime.add(minutes, 'minutes');
    return moment(currentTime).isBefore(allowedTime)
};

const lineMembersToArray = members => {
    const memberArr = [];
    if (!members) {
        return []
    }
    return Object.keys(members).map(key => ({id: key, ...members[key]}))
};

const isValidMember = (member, enterTime) => {
    if (!member.invitation) {
        return true
    }
    const invitation = member.invitation;
    const notExpired = isNotExpired(invitation.expiredOn, enterTime)
    return  notExpired && !invitation.scanned
};

const getValidInvitations = (invitations, members, enterTime) => {
    const validInvites = members.map(member => member.id);
    return invitations.filter(invite =>
        isNotExpired(invite.expiredOn, enterTime) && !invite.scanned && validInvites.includes(invite.userId))
};

const ProviderContent = (props) => {
    const {
        classes,
        firebase,
        line: { capacity, radius, enterTime, location, status, spots, maxPeople, slots, lineName, lineLogo },
        lines,
        userType,
        userId,
        callback
    } = props;

    const [ manageLine, setManageLine ] = React.useState(true);
    const [invitations, setInvitations] = React.useState([])

    React.useEffect(() => {
        firebase.loadInvitations({lineId: userId}, setInvitations)
    }, []);
    const action = actionProto({userId, userType}, callback);
    const action2 = actionProto2({providerId: userId, providerType: userType});
    const invitationSent = Number(invitations && invitations?.invitations?.length) || 0;
    const lineMembers = lineMembersToArray(lines.find(line => line.id === userId)?.members);
    const validInvitations = getValidInvitations(invitations, lineMembers, enterTime);
    const validMembers = lineMembers?.filter(member => isValidMember(member, enterTime));
    const usersInLine = validMembers?.length || 0;
    const slotsAvailable = (Number(capacity) || 0) - (Number(slots) || 0) - Number(validInvitations?.length || 0) * maxPeople;

    return (
        <div className={classes.root}>
            {manageLine &&
            <React.Fragment>
                <LineSettings
                    capacity={capacity}
                    enterTime={enterTime}
                    lineLogo={lineLogo}
                    lineName={lineName}
                    location={location}
                    onUpdate={action(firebase.onLineUpdate)}
                    radius={radius}
                    maxPeople={maxPeople}
                />
                <LineActions
                    onStatusUpdate={action(firebase.onLineStatusUpdate)}
                    onManageCustomers={() => setManageLine(false)}
                />
                <LineStatus
                    spots={usersInLine}
                    status={status}
                    slotsUsed={slots}
                    slotsAvailable={slotsAvailable}
                    onSendInvitation={action(firebase.onSendInvitation)}
                />
            </React.Fragment>
            }
            {!manageLine &&
                <ManageCustomers
                    onAddCustomer={action(firebase.onAddCustomer)}
                    onAddSlot={action(firebase.onAddSlot)}
                    slotsAvailable={slotsAvailable}
                    capacity={capacity}
                    onManageLine={() => setManageLine(true)}
                    invitations={invitations || []}
                    onInviteScan={action2(firebase.onInviteScan)}
                />
            }
        </div>

    )
};

ProviderContent.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProviderContent);
