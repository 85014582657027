import React, { PureComponent } from 'react';
import {
    ScatterChart, Scatter, XAxis, YAxis, ZAxis, ResponsiveContainer, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {withStyles} from "@material-ui/core";

interface ChartHouse {
    streetNumber: number,
    price: number,
    year: number,
    month: number
}

interface Chart {
    minPrice: number,
    maxPrice: number,
    data: Array<ChartHouse>
}

interface CustomTooltip {
    active: boolean
    payload: any
    label: string
}

const styles = {
     toolTip: {
        backgroundColor: '#fff',
     },
 };

const CustomTooltip = <P extends CustomTooltip>({ active, payload, label } : { active: boolean, payload: any, label: string }): (any | null) => {
    if (active) {
        return (
            <div style={styles.toolTip}>
                <p className="label">{`street number : ${payload[0].value}`}</p>
                <p className="label">{`built year : ${payload[0].payload.builtYear}`}</p>
                <p className="label">{`living area : ${payload[0].payload.livingArea}`}</p>
                <p className="label">{`land area : ${payload[0].payload.landArea}`}</p>
                <p className="label"><b>{`price :${payload[0].payload.price}`}</b></p>
                <p className="label"><b>{`PriceToArea ration :${payload[0].payload.priceAreaRatio}`}</b></p>
                <p className="label"><b>{`sold in :${payload[0].payload.year}-${payload[0].payload.month}`}</b></p>
            </div>
        );
    }

    return null;
};

class InsightChart extends PureComponent<Chart & CustomTooltip> {


    render() {
        const {minPrice, maxPrice, data} = this.props;
        // @ts-ignore
        return (
            <ResponsiveContainer width={'100%'} height={300}>
                <ScatterChart
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid />
                    <YAxis type="number" dataKey="price" name="Price" unit="$" />
                    <XAxis type="number" dataKey="streetNumber" name="Street Number" domain={[0, 'dataMax']} />
                    // @ts-ignore
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
                    <Legend />
                    <Scatter name="Sales" data={data} fill="#8884d8" shape="square" line /> />
                </ScatterChart>
            </ResponsiveContainer>
        );
    }
}

export default withStyles(styles)(InsightChart)

interface Tick {
    x?: number,
    y?: number,
    stroke?: number,
    payload?: {
        value: string
    }
}

class CustomizedAxisTick extends PureComponent<Tick> {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload && payload.value}</text>
            </g>
        );
    }
}

