import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './store/store';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Firebase, { FirebaseContext } from './components/Firebase';
import Login from './components/webUI/Login';
import VerifyEmail from './components/webUI/VerifyEmail';
import Logout from './components/webUI/Logout';
import Reset from './components/webUI/Reset';
import NoMatch from './components/webUI/NoMatch';
import UserLayout from './components/Layouts/UserLayout';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import getPageContext from './components/share/getPageContext';
import CreateAccount from "./components/webUI/CreateAccount";
import AppHeader from "./components/webUI/AppHeader";
import PrivacyPolicy from "./components/webUI/PrivacyPolicy";
import App from './App';


const store = configureStore()
const pageContext = getPageContext();

// @ts-ignore
ReactDOM.render(
    <Provider store={store}>
        <FirebaseContext.Provider value={new Firebase()}>
            <MuiThemeProvider
                theme={pageContext.theme}
            >
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Router>
                    <div>
                        <AppHeader />
                        <Switch>
                            <Route exact path="/" component={App} />
                            <ProtectedRoute path="/user" component={UserLayout} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/signup" component={CreateAccount} />
                            <Route exact path="/signout" component={Logout} />
                            <Route exact path="/reset" component={Reset} />
                            <Route exact path="/verify-email" component={VerifyEmail} />
                            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                            <Route component={NoMatch}/>
                        </Switch>
                    </div>
                </Router>
            </MuiThemeProvider>
        </FirebaseContext.Provider>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
