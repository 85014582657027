import React from 'react';
import VerifyEmail from '../Auth/VerifyEmail';

import  { FirebaseContext } from '../Firebase';

const Login = (props) => (
    <FirebaseContext.Consumer>
        {firebase => (
            <div>
                <VerifyEmail {...props} firebase={firebase}/>
            </div>)
        }
    </FirebaseContext.Consumer>
);

export default Login;
