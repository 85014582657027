import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Formik} from "formik";
import red from '@material-ui/core/colors/red';
import { authSuccess } from '../../store/actions';
import {connect} from "react-redux";
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom'
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    registerArea: {
        marginTop: 30,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center'
    },
    registerButton: {
        textAlign: 'center'
    }

});

class SignIn extends Component {
    render()
    {
        const { classes, firebase } = this.props;
        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Formik
                        initialValues={{email: '', password: '', type: ''}}
                        validate={values => {
                            let errors = {};
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address';
                            }
                            if(!values.type) {
                                errors.type = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            firebase
                                .doSignInWithEmailAndPassword(values.email, values.password)
                                .then(authUser => {
                                    setSubmitting(false);
                                    const user = {
                                        uid: authUser.user.uid,
                                        email: authUser.user.email,
                                        isEmailVerified: authUser.user.emailVerified,
                                        lastSignInTime: authUser.user.metadata.lastSignInTime,
                                        creationTime: authUser.user.metadata.creationTime,
                                    };
                                    firebase.loadUserProfile(user.uid, values.type).then(userProfile => {
                                        this.props.authSuccess({...user, ...userProfile, type: values.type});
                                        this.props.history.push('/user/home');
                                    })
                                })
                                .catch(error => {
                                    console.log(error)
                                    setSubmitting(false);
                                    setErrors({email: true})
                                });
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="email">Email Address</InputLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            autoComplete="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            autoFocus
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input
                                            name="password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Are you...</FormLabel>
                                        <RadioGroup aria-label="gender" name="type" value={values.type} onChange={handleChange}>
                                            <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                                            <FormControlLabel value="provider" control={<Radio />} label="Provider" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary"/>}
                                        label="Remember me"
                                    />
                                    {errors.email && touched.email && errors.email &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Authentication Failed
                                    </Typography>
                                    }
                                    {errors.type && touched.type && errors.type &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Required
                                    </Typography>
                                    }
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Sign in
                                    </Button>
                                </form>
                            )}
                    </Formik>
                    <div className={classes.registerArea}>
                        <Divider variant="middle" />
                        <Typography variant='body2' className={classes.text}>Don't have an account?</Typography>
                        <Button
                            component={Link}
                            to={'/signup'}
                            fullWidth
                            color="primary"
                            className={classes.registerButton}
                        >
                            Register
                        </Button>
                        <Divider variant="middle" />
                        <Typography variant='body2' className={classes.text}>Forgot your password?</Typography>
                        <Button
                            component={Link}
                            to={'/reset'}
                            fullWidth
                            color="primary"
                            className={classes.registerButton}
                        >
                            Reset Password
                        </Button>
                    </div>
                </Paper>
            </main>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { user } = state.user;
    return { user}
};


export default connect(mapStateToProps, {authSuccess})(withStyles(styles)(SignIn));
