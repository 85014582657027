import {FirebaseContext} from "../Firebase";
import AdminAppBar from "./AdminAppBar";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {tabsChanged} from "../../store/actions";
import React from "react";
import { withRouter } from "react-router-dom";

const AppHeader = (props) => (
    <FirebaseContext.Consumer>
        {firebase => (
            <div>
                <AdminAppBar {...props} firebase={firebase} />
            </div>)
        }
    </FirebaseContext.Consumer>
);

AppHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { isMenuOpen } = state.admin;
    return { isMenuOpen}
};


export default withRouter(connect(mapStateToProps, { tabsChanged })(AppHeader));
