import React from 'react';
import PropTypes, {object} from "prop-types";
import {DialogContent, withStyles} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/sample-logo.png';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import QRCode from 'qrcode.react'
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import QrReader from 'react-qr-reader'
import clsx from "clsx";

const styles = theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    dialog: {
        margin: 10,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    expired: {
        color: 'red',
        fontSize: 14,
        textAlign: 'center',
    },
    valid: {
        borderWidth: 3,
        borderColor: 'green',
    },
    invalid: {
        borderWidth: 6,
        borderColor: 'orange',
    },
    center: {
        textAlign: 'center',
    }
});


const ScanQR = (props) => {
    const { classes, invitations, onInviteScan } = props;
    const [scannedCode, setScannedCode] = React.useState(null);
    const [isShowingScanner, openScanner] = React.useState(false);
    const [isValid, setInviteValidation] = React.useState({});
    const [invite, setInvite] = React.useState(null);

    React.useEffect(() => {
        const hasInvitation = invitations?.find(invite => invite.id === scannedCode);
        // is Valid? === isNot expired and has not been scanned
        if (hasInvitation)  {
            setInvite(hasInvitation);
            onInviteScan(hasInvitation)
        } else {
            setInvite(null)
        }
    }, [scannedCode]);

    React.useEffect(() => {
        if (invite) {
            validate({invite}, setInviteValidation)
        } else {
            setInviteValidation({})
        }
    }, [invite]);

    //const loadInvite = checkInvite(setInvite);
    const handleScan = data => {
        if (data) {
            setScannedCode(data)
        }
    };

    const handleError = err => {
        console.error(err)
    };

    //
    return(
        <React.Fragment>
            <div className={classes.root}>
                <Button
                    size="small"
                    color="primary"
                    onClick={() => openScanner(true)}
                >
                    Scan QR-Code
                </Button>
                <Dialog onClose={() => openScanner(false)} open={isShowingScanner}>
                    <DialogTitle id="simple-dialog-title">Scan QR-Code from Customer device</DialogTitle>
                    <DialogContent className={clsx(classes.dialog, invite && isValid?.status && classes.valid, invite && !isValid?.status && classes.invalid)} dividers>
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                        />
                        {scannedCode &&
                        <div className={classes.center}>
                            {renderResponse({invite, isValid})}
                        </div>
                        }
                    </DialogContent>
                    <DialogActions>

                        <Button
                            autoFocus
                            onClick={() => {
                                setScannedCode(null)
                                openScanner(false)
                            }}
                            color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    )
};

const validate = ({invite}, callback) => {
    const currentTime = moment();
    const inveiteTime = moment(invite.expiredOn, 'x');
    const isExpired = moment(currentTime).isAfter(inveiteTime);
    // const isValidInvite = !isExpired && !invite.scanned;
    const isValid = {
        status: !isExpired && !invite.scanned,
        scanned: invite.scanned,
        expired: isExpired
    };
    callback(isValid)
};

const renderResponse = ({invite, isValid}) => {

    if (invite && isValid.status) {
        return (
            <React.Fragment>
                <Typography variant="h5">VALID</Typography>
                <Typography variant="body1">Customer may proceed</Typography>
            </React.Fragment>
        )
    } if (invite) {
        return (
            <React.Fragment>
                <Typography variant="h6">INVALID</Typography>
                {isValid.expired &&
                <Typography variant="body1">Invitation expired
                    on: {moment(invite.expiredOn, 'x').format('hh:mma')}</Typography>
                }
                {isValid.scanned &&
                <Typography variant="body1">Invitation has been scanned already.</Typography>
                }
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Typography variant="h6">INVALID</Typography>
            </React.Fragment>
        )
    }
};

ScanQR.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(ScanQR);
