import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MailIcon from '@material-ui/icons/Mail';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Formik} from "formik";
import red from '@material-ui/core/colors/red';
import { logedOut } from '../../store/actions';
import {connect} from "react-redux";
import { APP_CONSTS } from '../share/variables';
import { Redirect } from 'react-router'
import {Link} from "react-router-dom";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    register: {
        marginTop: 30,
        width: '100%'
    }

});

class ResetRequest extends Component {
    state = {
        isSent: false
    };

    render()
    {
        const { classes, firebase } = this.props;

        if (this.state.isSent) {
            return (
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <MailIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Your reset request has been sent! Check your email.
                        </Typography>
                        <div>
                            <Button
                                component={Link}
                                to={'/login'}
                                fullWidth
                                color="primary"
                                className={classes.registerButton}
                            >
                                Login
                            </Button>
                        </div>
                    </Paper>
                </main>
            )
        }

        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <MailIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Send a reset request
                    </Typography>
                    <Formik
                        initialValues={{email: ''}}
                        validate={values => {
                            let errors = {};
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address';
                            }

                            return errors;
                        }}
                        onSubmit={(values, {setErrors}) => {
                            firebase
                                .doPasswordReset(values.email)
                                .then(() => {
                                    this.setState({isSent: true});
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({isSent: true});
                                });
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) =>
                            (<form className={classes.form} onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="email">Email Address</InputLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            autoComplete='off'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            autoFocus
                                        />
                                    </FormControl>

                                    {errors.email && touched.email && errors.email &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        {errors.email}
                                    </Typography>
                                    }

                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Send Request
                                    </Button>
                                </form>
                            )}
                    </Formik>
                </Paper>
            </main>
        );
    }
}

ResetRequest.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { user } = state.user;
    return { user}
};


export default connect(mapStateToProps, {logedOut})(withStyles(styles)(ResetRequest));
