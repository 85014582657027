import React from 'react';
import PropTypes, {object} from "prop-types";
import {DialogContent, withStyles} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/sample-logo.png';
import { getDistance } from 'geolib';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const styles = theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
        minWidth: 260,
    },
    dialog: {
        margin: 10,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
});


const LineTile = (props) => {
    const { classes, line, onJoin, location } = props;
    const [openInfo, setOpenInfo] = React.useState(false)
    if (!line) {
        return null
    }
    const distanceToLine = location && getDistance(location, line.gps);
    const isWithinRadius = location && distanceToLine <= line.radius;
    return(
        <React.Fragment>
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={line.lineLogo || logo}
                    title={line.title || 'Sample Line'}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {line.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        In line: {line.members && line.members.length || 0}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {!location && <Typography variant="caption">You can't join a line without location enabled.</Typography> }
                {location && !isWithinRadius && <Typography variant="caption">You have to be within {line.radius} metres to join a line.</Typography> }
                <Button
                    size="small"
                    color="primary"
                    disabled={!location || !isWithinRadius}
                    onClick={() => onJoin({lineId: line.id, lineLocation: line.location})}
                >
                    Join Line
                </Button>
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => setOpenInfo(true)}
                >
                    More Info
                </Button>
            </CardActions>
        </Card>
        <Dialog onClose={() => setOpenInfo(false)} open={openInfo}>
            <DialogTitle id="simple-dialog-title">Line Info</DialogTitle>
            <DialogContent className={classes.dialog} dividers>
                {line && renderLine(line)}
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => setOpenInfo(false)}
                    color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>

    )
}

LineTile.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(LineTile);

const renderLine = line => {
    const row = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    };
    return (
        <React.Fragment>
            <div style={row}>
                <Typography variant="body1">Store Name: </Typography>
                <Typography variant="h6">{line.lineName}</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Max distance to get in line: </Typography>
                <Typography variant="h6">{line.radius} metres</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Time to enter after receiving invitation: </Typography>
                <Typography variant="h6">{line.enterTime} minutes</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">People allowed to enter in one spot: </Typography>
                <Typography variant="h6">{line.maxPeople} people</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Store capacity: </Typography>
                <Typography variant="h6">{line.capacity} people</Typography>
            </div>
            <div style={row}>
                <Typography variant="body1">Store location: </Typography>
                <Typography variant="h6">{line.location}</Typography>
            </div>
        </React.Fragment>
    );
};

