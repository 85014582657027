import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import  { FirebaseContext } from './components/Firebase/';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SecurityIcon from '@material-ui/icons/Security';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardMedia from '@material-ui/core/CardMedia';
import copy1Image from './assets/images/copi-image1.jpg'

interface App {
   firebase: any
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    gridContainer: {
      width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperTransparent: {
        color: theme.palette.text.primary,
        backgroundColor: '#ffffff96',
    },
    media: {
        height: 340,
    },
}));


const App: React.FC<App> = (props: App) => {
    const classes = useStyles();

    return (
    <div className="App">
        <header className="App-header">
            <div className="Logo-Container">
                <Grid
                    container
                    spacing={5}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.gridContainer}
                >
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <CardMedia
                                className={classes.media}
                                image={copy1Image}
                                title="In a car"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paperTransparent}>
                            <Typography variant="h3">LineThru</Typography>
                            <Typography variant="h3">Gives Convenience and Safety</Typography>
                            <Typography variant="h4">An access platform and line organizer</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paperTransparent}>
                            <Typography variant="h5">It's FREE for offices, schools, stores, and anyone who cares about health and safety of its customers</Typography>
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        </header>
        <section className="App-Section">
            <article className="App-Article">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h2" paragraph>
                                LineThru Helps
                            </Typography>
                            <Typography variant="h2" paragraph>
                                Offices, Schools, Stores, and Customers
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h3" paragraph>
                                Customers, Students, and Visitors
                            </Typography>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemIcon>
                                        <SecurityIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Stay safe" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <EventSeatIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Wait in line from the comfort of own car or even at home" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <AlarmAddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Save time" />
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h3" paragraph>
                                Schools and Offices
                            </Typography>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemIcon>
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Implement and follow health orders" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <SupervisorAccountIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Manage property capacity" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <SupervisorAccountIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Manage access and automate entry process into the property" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <AttachMoneyIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Save money" />
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </article>
        </section>
        <section className="App-Section">
            <article className="App-Article">
                <Typography variant="h2" paragraph>
                    Ask your favorite store about LineThru
                </Typography>
                <Typography variant="h4" paragraph>
                    Only a store (Provider) can create a convenient line
                </Typography>
                <Typography variant="body1" paragraph>
                    Register in the app. It's free.
                </Typography>
                <Typography variant="body1" paragraph>
                    Find your favorite store line and join it.
                </Typography>
                <Typography variant="body1" paragraph>
                    Wait for notification to get inside.
                </Typography>
                <Typography variant="body1" paragraph>
                    Show your personal QR code at the entrance.
                </Typography>
                <Typography variant="h6" paragraph>
                    You are inside
                </Typography>
            </article>
        </section>

        <section className="App-Section">
            <article className="App-Article">
                <Typography variant="h2" paragraph>
                    Disclaimers
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not share any user's information with any third party.
                </Typography>
                <Typography variant="h5">
                    Terms and Conditions
                </Typography>
                <Typography variant="body1" paragraph>
                    If you use this application you agree that we do not guarantee anything.
                    All services provided 'AS-IS' and we cannot guarantee its accuracy and reliability.
                    All lines settings are up to their providers.
                    This application is currently under development and it is possible a disruption in service may
                    occur.
                </Typography>
            </article>
        </section>
        <div className="App-Section even">
            <Grid
                direction="row"
                justify="center"
                container
                spacing={3}
            >
                <Grid item xs={12}>
                    <Typography variant="h6" paragraph>
                        Contact us
                    </Typography>
                    <Typography variant="caption" paragraph>
                        inquires@linethru.com
                    </Typography>
                </Grid>
            </Grid>
        </div>
    </div>
  );
};

const WrappedApp = (props: any) => (
    <FirebaseContext.Consumer>
        {(firebase: any) => (
            <App {...props} firebase={firebase} />
        )}
    </FirebaseContext.Consumer>
);

export default WrappedApp;




