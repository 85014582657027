import {actionTypes} from "./store";
import {combineReducers} from "redux";

const initialState = {
    probability: null,
    termsAccepted: false,
    report: null,
    tree: null,
    assets: null
};

const initialUserState = {
    isAuthenticated: false,
    user: null
};

const initialAdminState = {
    isCreateTreeDialogOpen: false,
    isEditTreeDialogOpen: false,
    isEditQuestionDialogOpen: false,
    isCreateQuestionDialogOpen: false,
    isSwapAssetsDialogOpen: false,
    isEditAssetDialogOpen: false,
    editedAsset: null,
    editedTree: null,
    editedQuestion: null,
    activeTab: 0,
    isMenuOpen: false
};

// REDUCERS
const appState = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCEPT_TERMS:
            return Object.assign({}, state, {
                termsAccepted: true
            })
        case actionTypes.SET_TREE:
            return Object.assign({}, state, {
                tree: action.payload
            })
        case actionTypes.SET_ASSETS:
            return Object.assign({}, state, {
                assets: action.payload
            })
        case actionTypes.SET_PROBABILITY:
            return Object.assign({}, state, {
                probability: action.payload
            })
        case actionTypes.UPSERT_REPORT:
            const { answer } = action.payload;
            let newReport = {};
            if (state.report && state.report[`${answer.questionId}`]) {
                for (let el of Object.keys(state.report)) {

                    if (el === answer.questionId) {
                        newReport[`${el}`] = answer.answer;
                        break;
                    } else {
                        newReport[`${el}`] = state.report[`${el}`];
                    }
                }
            } else if (state.report && Object.entries(state.report).length > 0) {
                for (let el of Object.keys(state.report)) {
                    if (state.report[`${el}`].nextQuestion === answer.questionId) {
                        newReport[`${el}`] = state.report[`${el}`];
                        newReport[`${answer.questionId}`] = answer.answer;
                        break;
                    } else {
                        newReport[`${el}`] = state.report[`${el}`];
                    }
                }
            } else {
                newReport[`${answer.questionId}`] = answer.answer;
            }

            return Object.assign({}, state, {
                probability: action.payload.probability,
                report: newReport
            })
        case actionTypes.RESET:
            return Object.assign({}, state, initialState)
        default:
            return state
    }
}

const user = (state = initialUserState, action) => {
    switch (action.type) {
        case actionTypes.AUTHENTICATION_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: true,
                user: action.payload
            })
        case actionTypes.AUTHENTICATION_FAIL:
            return Object.assign({}, state, {
                isAuthenticated: false
            })
        case actionTypes.LOGGED_OUT:
            return Object.assign({}, state, {
                isAuthenticated: false,
                user: null
            })
        case actionTypes.UPDATE_LINE:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    ...{ line: action.payload }
                }
            })
        case actionTypes.UPDATE_USER_LINES:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    ...{ userLines: action.payload }
                }
            })
        default:
            return state
    }
}

const defaultNetwork = {
    token: null,
};

const aiNetwork = (state = defaultNetwork, action) => {
    switch (action.type) {
        case actionTypes.TOKEN_RECEIVED:
            return {...state, token: action.payload};
        default:
            return state
    }
}

const admin = (state = initialAdminState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_CREATE_TREE_DIALOG:
            return Object.assign({}, state, {
                isCreateTreeDialogOpen: true
            })
        case actionTypes.CLOSE_CREATE_TREE_DIALOG:
            return Object.assign({}, state, {
                isCreateTreeDialogOpen: false
            })
        case actionTypes.OPEN_SWAP_ASSETS_DIALOG:
            return Object.assign({}, state, {
                isSwapAssetsDialogOpen: true
            })
        case actionTypes.CLOSE_SWAP_ASSETS_DIALOG:
            return Object.assign({}, state, {
                isSwapAssetsDialogOpen: false
            })
        case actionTypes.OPEN_EDIT_TREE_DIALOG:
            return Object.assign({}, state, {
                isEditTreeDialogOpen: true,
                editedTree: action.payload
            })
        case actionTypes.CLOSE_EDIT_TREE_DIALOG:
            return Object.assign({}, state, {
                isEditTreeDialogOpen: false,
                editedTree: null
            })
        case actionTypes.OPEN_EDIT_ASSET_DIALOG:
            return Object.assign({}, state, {
                isEditAssetDialogOpen: true,
                editedAsset: action.payload
            })
        case actionTypes.CLOSE_EDIT_ASSET_DIALOG:
            return Object.assign({}, state, {
                isEditAssetDialogOpen: false,
                editedAsset: null
            })
        case actionTypes.OPEN_EDIT_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isEditQuestionDialogOpen: true,
                editedQuestion: action.payload
            })
        case actionTypes.CLOSE_EDIT_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isEditQuestionDialogOpen: false,
                editedQuestion: null
            })
        case actionTypes.OPEN_CREATE_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isCreateQuestionDialogOpen: true
            })
        case actionTypes.CLOSE_CREATE_QUESTION_DIALOG:
            return Object.assign({}, state, {
                isCreateQuestionDialogOpen: false
            })
        case actionTypes.CREATE_NEW_TREE:
            return Object.assign({}, state, {
                isCreateTreeDialogOpen: false,
            })
        case actionTypes.CREATE_NEW_QUESTION:
            return Object.assign({}, state, {
                isCreateQuestionDialogOpen: false,
            })
        case actionTypes.TABS_CHANDED:
            return Object.assign({}, state, {
                activeTab: action.payload,
            })
        case actionTypes.MENU_OPEN:
            return Object.assign({}, state, {
                isMenuOpen: true,
            })
        case actionTypes.MENU_CLOSED:
            return Object.assign({}, state, {
                isMenuOpen: false,
            })
        case actionTypes.TASTE_ADDED:
            return state;
        default:
            return state
    }
}




export default (history) => combineReducers({
    appState,
    user,
    admin,
    aiNetwork,
});
