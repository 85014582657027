export const STYLES_CONSTS = {
    GREEN_BUTTON: '#24CD7E',
    BLUE_BUTTON: '#13CBE3',
    DISABLED_COLOR: '#DDDDDD',
    PREVIOUS_BUTTON: '#3C3C3C',
    NOSCORE_COLOR: '#10DD6D',
    WHITE_COLOR: '#ffffff',
    TEXT_COLOR: '#3B3B3B',
    QUESTION_COLOR: '#00C3DD',
    INTRO_TEXT_COLOR: '#494949',
    INTRO_TITLE_COLOR: '#13CBE3',
    HEADING_COLOR: '#343434',
    DISCLAIMER_COLOR: '#242424',
    PRINT_ASSESSMENT_COLOR: '#0DB0C5',
    NEXT_STEPS_COLOR: '#0D93A4'
};

export const APP_CONSTS = {
    PASSWORD_LENGTH: 8
}