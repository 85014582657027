import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {connect} from "react-redux";
import {
    openDashboardMenu, closeDashboardMenu
} from "../../store/actions";
import { Link } from 'react-router-dom'
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    titleMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    titleLink: {
        textDecoration: 'none',
        color: '#fff',
        flexGrow: 1,
    }
});

let btn = React.createRef();


const AdminAppBar = props => {


    const { classes, isMenuOpen, history, user, firebase, setAIServerToken } = props;

    useEffect(() => {
        firebase.auth.onAuthStateChanged(authUser => {
            if(authUser) {
                return firebase.auth.currentUser.getIdToken()
                    .then(idToken => {
                        //setAIServerToken(idToken);
                        // Any extra code
                    }).catch();
            }
        });
    }, []);


    const handleClose = event => {
        props.closeDashboardMenu()
    };

    const handleHome = event => {
        history.push('/');
        props.closeDashboardMenu()
    };

    const handleSubscription = () => {
        props.closeDashboardMenu();
        history.push('/user/subscription');
    };

    const handlePrivacy = () => {
        props.closeDashboardMenu();
        history.push('/privacy-policy');
    };

    const handleSignout = () => {
        props.closeDashboardMenu();
        history.push('/signout');
    };

    const handleSignin = () => {
        props.closeDashboardMenu();
        history.push('/login');
    };

    const handleSignUp = () => {
        props.closeDashboardMenu();
        history.push('/signup');
    };
    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={() => props.openDashboardMenu()}
                        buttonRef={node => btn = node}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Popper open={isMenuOpen} anchorEl={btn} placement='bottom-end'>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem onClick={handleHome}>Home</MenuItem>
                                    <Divider />
                                    {user && <MenuItem onClick={handleSignout}>Logout</MenuItem>}
                                    {!user && <MenuItem onClick={handleSignUp}>Sign Up</MenuItem>}
                                    {!user && <MenuItem onClick={handleSignin}>Login</MenuItem>}
                                    <Divider />
                                    <MenuItem onClick={handlePrivacy}>Privacy Policy</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                    <Link className={classes.titleLink} to={'/'}>
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            LineThru - the most convenient way to wait in line
                        </Typography>
                        <Typography className={classes.titleMobile} variant="h6" color="inherit" noWrap>
                           LineThru
                        </Typography>
                    </Link>
                    <Button color="inherit" onClick={handleSignUp}>Sign Up</Button>
                    <Button color="inherit" onClick={handleSignin}>Login</Button>
                </Toolbar>
            </AppBar>
        </div>
    );
};

AdminAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    openDashboardMenu: PropTypes.func.isRequired,
    closeDashboardMenu: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool.isRequired
};


const mapStateToProps = state => {
    const {isMenuOpen} = state.admin;
    const {user} = state.user;

    return {user, isMenuOpen}
};


export default connect(mapStateToProps, {
    openDashboardMenu,
    closeDashboardMenu
})(withStyles(styles)(AdminAppBar));
