import Dashboard from '../webUI/Dashboard';
import React from "react";
import {Route, Switch} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import SubscriptionView from "../webUI/Subscription";

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 90,
    },
});

const UserLayout = (props) =>
            <div className={props.classes.root}>
                <Switch>
                    <Route exact path="/user/home" component={Dashboard} />
                    <Route path="/user/subscription" component={SubscriptionView} />
                </Switch>
            </div>;


export default withStyles(styles)(UserLayout);
