import React from 'react';
import Typography from '@material-ui/core/Typography';
import ContentArea from './ContentArea';
import {FirebaseContext} from "../Firebase";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { tabsChanged } from "../../store/actions";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const Dashboard = (props) => (
   <FirebaseContext.Consumer>
       {firebase => <ContentArea {...props} firebase={firebase} />}
   </FirebaseContext.Consumer>
);

Dashboard.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const { activeTab, isMenuOpen } = state.admin;
    return {activeTab, isMenuOpen}
};


export default connect(mapStateToProps, { tabsChanged })(withStyles(styles)(Dashboard));
