import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from '@material-ui/core/colors/red';
import { logedOut } from '../../store/actions';
import {connect} from "react-redux";
import { Redirect } from 'react-router'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    register: {
        marginTop: 30,
        width: '100%'
    }

});

class Signup extends Component {
    state = {
        redirect: false
    };

    signout = () =>{
        this.props.firebase.doSignOut()
            .then(() =>
        {
            this.props.logedOut();
            this.props.history.push('/');
        })
            .catch(error => {
                console.log(error)
            });
    };

    render()
    {
        const { classes } = this.props;
        if (this.state.redirect) {
            return <Redirect to='/'/>;
        }

        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <ExitToAppIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        You are about to signout from the site...
                    </Typography>
                    <div>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.signout}
                        >
                            Logout
                        </Button>
                    </div>
                </Paper>
            </main>
        );
    }
}

Signup.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { user } = state.user;
    return { user}
};


export default connect(mapStateToProps, {logedOut})(withStyles(styles)(Signup));
