import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'

export const actionTypes = {
    SET_PROBABILITY: 'SET_PROBABILITY',
    ACCEPT_TERMS: 'ACCEPT_TERMS',
    SET_TREE: 'SET_TREE',
    SET_ASSETS: 'SET_ASSETS',
    RESET: 'RESET',
    AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
    AUTHENTICATION_FAIL: 'AUTHENTICATION_FAIL',
    LOGGED_OUT: 'LOGGED_OUT',
    UPDATE_LINE: 'UPDATE_LINE',
    UPDATE_USER_LINES: 'UPDATE_USER_LINES',
    OPEN_CREATE_TREE_DIALOG: 'OPEN_CREATE_TREE_DIALOG',
    OPEN_EDIT_ASSET_DIALOG: 'OPEN_EDIT_ASSET_DIALOG',
    OPEN_SWAP_ASSETS_DIALOG: 'OPEN_CREATE_SWAP_ASSETS_DIALOG',
    CLOSE_SWAP_ASSETS_DIALOG: 'CLOSE_SWAP_ASSETS_DIALOG',
    CLOSE_CREATE_TREE_DIALOG: 'CLOSE_CREATE_TREE_DIALOG',
    CLOSE_EDIT_ASSET_DIALOG: 'CLOSE_EDIT_ASSET_DIALOG',
    CLOSE_EDIT_TREE_DIALOG: 'CLOSE_EDIT_TREE_DIALOG',
    OPEN_EDIT_TREE_DIALOG: 'OPEN_EDIT_TREE_DIALOG',
    OPEN_CREATE_QUESTION_DIALOG: 'OPEN_CREATE_QUESTION_DIALOG',
    OPEN_EDIT_QUESTION_DIALOG: 'OPEN_EDIT_QUESTION_DIALOG',
    CLOSE_EDIT_QUESTION_DIALOG: 'CLOSE_EDIT_QUESTION_DIALOG',
    CLOSE_CREATE_QUESTION_DIALOG: 'CLOSE_CREATE_QUESTION_DIALOG',
    CREATE_NEW_TREE: 'CREATE_NEW_TREE',
    CREATE_NEW_QUESTION: 'CREATE_NEW_QUESTION',
    TABS_CHANDED: 'TABS_CHANDED',
    UPSERT_REPORT: 'UPSERT_REPORT',
    MENU_OPEN: 'MENU_OPEN',
    MENU_CLOSED: 'MENU_CLOSED',
    TASTE_ADDED: 'TASTE_ADDED',
    TOKEN_RECEIVED: 'TOKEN_RECEIVED',
    REPORT_SENT: 'REPORT_SENT',
};

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware()

export function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeWithDevTools(
            applyMiddleware(sagaMiddleware)
        ),
    );

    return store
}
