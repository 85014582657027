import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";
import ImagePreview from "./ImagePreview";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formRow: {
        alignSelf: 'stretch', // Fix IE 11 issue.
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    registerArea: {
        marginTop: 30,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center'
    },
    registerButton: {
        textAlign: 'center'
    },
    imageContainer: {
        height: 100,
        width: 100,
        borderRadius: 20,
        borderWidth: 0,
        borderColor: 'red',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

const LineSettings = (props) => {
    const { capacity, classes, enterTime, location, onUpdate, radius, lineName, lineLogo, maxPeople } = props;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Line Settings</Typography>
                <Formik
                    initialValues={{capacity, enterTime, location, radius, lineName, lineLogo, newLineLogo: null, maxPeople}}
                    validate={values => {
                        let errors = {};
                        if (!values.capacity) {
                            errors.capacity = 'Required';
                        }
                        if (!values.enterTime) {
                            errors.enterTime = 'Required';
                        }
                        if (!values.radius) {
                            errors.radius = 'Required';
                        }
                        if (!values.location) {
                            errors.location = 'Required';
                        }
                        if (!values.lineName) {
                            errors.lineName = 'Required';
                        }

                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        onUpdate(values)
                            .then(done => {
                                setSubmitting(false);
                            })
                            .catch(error => {
                                console.log(error);
                                setSubmitting(false);
                                setErrors({update: true})
                            });
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* and other goodies */
                      }) =>
                        (<form className={classes.form} onSubmit={handleSubmit}>
                                <div className={classes.formRow}>
                                    <FormControl margin="normal" className={classes.inputRow}>
                                        {!values.newLineLogo && values.lineLogo &&
                                        <div className={classes.imageContainer}>
                                            <img className={classes.imageContainer} src={values.lineLogo} alt="Logo"/>
                                        </div>
                                        }
                                        {values.newLineLogo &&
                                        <ImagePreview
                                            file={values.newLineLogo}
                                            styles={classes.imageContainer}
                                        />
                                        }
                                        <label htmlFor="upload-button">
                                            <Button size="small" variant="contained" component="span" className={classes.button}>
                                                Upload Image
                                            </Button>
                                        </label>
                                        <input
                                            accept="image/png"
                                            className={classes.input}
                                            style={{ display: 'none' }}
                                            id="upload-button"
                                            multiple
                                            type="file"
                                            name="newLineLogo"
                                            onChange={(event) => {
                                                setFieldValue("newLineLogo", event.currentTarget.files[0]);
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={classes.formRow}>
                                    <FormControl margin="normal" required>
                                        <InputLabel htmlFor="lineName">Line Name</InputLabel>
                                        <Input
                                            id="lineName"
                                            name="lineName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lineName}
                                        />
                                    </FormControl>
                                </div>
                                <div className={classes.formRow}>
                                    <FormControl margin="normal" required>
                                        <InputLabel htmlFor="capacity">Store Capacity</InputLabel>
                                        <Input
                                            id="capacity"
                                            name="capacity"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.capacity}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required>
                                        <InputLabel htmlFor="location">Store Location</InputLabel>
                                        <Input
                                            name="location"
                                            id="location"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.location}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required>
                                        <InputLabel htmlFor="radius">Line Radius (meters)</InputLabel>
                                        <Input
                                            name="radius"
                                            id="radius"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.radius}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required>
                                        <InputLabel htmlFor="enterTime">Enter Time (minutes)</InputLabel>
                                        <Input
                                            name="enterTime"
                                            id="enterTime"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.enterTime}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required>
                                        <InputLabel htmlFor="maxPeople">Max people</InputLabel>
                                        <Input
                                            name="maxPeople"
                                            id="maxPeople"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.maxPeople}
                                        />
                                    </FormControl>
                                    {(errors.capacity || errors.location || errors.radius || errors.enterTime
                                        || errors.lineName || errors.maxPeople) &&
                                    <Typography component="h5" variant="h5" className={classes.alert}>
                                        Required
                                    </Typography>
                                    }
                                </div>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Update Settings
                                </Button>
                            </form>
                        )}
                </Formik>
            </Paper>
        </div>

    )
};

LineSettings.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(LineSettings);
