import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

const firebaseConfigSets = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABHASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECY_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const STREET_TYPES_2 = [
    ['DR', 'DRIVE'],
    ['AVE', 'AVENUE'],
    ['ST', 'STREET'],
    ['PL', 'PLACE'],
    ['CRES', 'CRESCENT'],
    ['LANE'],
    ['BLVD', 'BOULEVARD'],
    ['RD', 'ROAD'],
    ['BAY'],
    ['COVE'],
    ['CRT', 'COURT'],
    ['CORNER', 'COR'],
    ['CLOSE'],
    ['WAY'],
    ['ROW'],
    ['GDN', 'GARDENS', 'GARDEN', 'GDNS'],
    ['HWY', 'HIGHWAY'],
    ['KEY'],
    ['PT', 'POINT'],
    ['PK', 'PARK'],
    ['CIR', 'CIRCLE'],
    ['TRAIL'],
    ['TERR', 'TERRACE', 'TR'],
    ['GROVE'],
    ['GATE'],
    ['SQ', 'SQUARE'],
    ['COMMON', 'CMN'],
    ['PKY', 'PARKWAY'],
    ['PATH'],
    ['WALK'],
    ['PROM', 'PROMENADE'],
    ['MEWS'],
    ['RDG', 'RIDGE'],
    ['RUN'],
    ['FWY', 'FREEWAY'],
    ['MEADOW']
];

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfigSets);
        this.auth = app.auth();
        this.database = app.database();
        this.storage = app.storage();
        this.onLineUpdate = this.onLineUpdate.bind(this)

    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
            .then(() =>
                this.auth.signInWithEmailAndPassword(email, password))
            .catch();

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    getUrl = url => this.storage.refFromURL(url).getDownloadURL();

    hasUserEmailVerified = password => this.auth.currentUser.emailVerified;

    verifyEmail = ({oobCode}, callback) => {
        this.auth.applyActionCode(oobCode).then(res => {
            callback({status: 'success', res})
        }).catch(res =>{
            callback({status: 'error', res})
        })
    };

    resendUserEmailVerification = () => {
        return new Promise((resolve, reject) => {
            const user = this.auth.currentUser;
            user.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    //console.log('user', idTokenResult)
                })
                .catch((error) => {
                    console.log(error);
                });
            user.sendEmailVerification().then(function() {
                // Email sent.
                resolve({done: true});
            }).catch(function(error) {
                // An error happened.
                reject(error);
            });
        });
    };



    createUserProfile = ({uid, email, isEmailVerified, type, line, lines}) => {
        return new Promise((resolve, reject) => {
            const thePath = `users/${type}/${uid}/`;
            const genRef = this.database.ref(thePath);
            const user = {
                uid,
                email,
                isEmailVerified,
            };
            if (line) {
                user.line = line
            }
            if (lines) {
                user.lines = lines
            }
            genRef
                .set({
                    ...user
                }, error => {
                if (error) {
                    reject(error);
                } else {
                    const user = this.auth.currentUser;
                    user.sendEmailVerification().then(function() {
                        // Email sent.
                        resolve({done: true});
                    }).catch(function(error) {
                        // An error happened.
                    });

                }
            });
        });
    };

    async getGPSByAddress(location) {
        let gps;
        if (location) {
            let URL_str = `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${firebaseConfigSets.apiKey}`;
            gps = await fetch(URL_str, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                });
        }
        return gps.json()
    }

    async getLogoLink(userId, logoFile) {
        const storageRef = this.storage.ref();
        const logosFolderRef = storageRef.child('logos');
        const logoRef = logosFolderRef.child(`${userId}.png`);
        return logoRef.put(logoFile).then(() => logoRef.getDownloadURL())
    }

    async onLineUpdate({userId, userType, capacity, enterTime, location, radius, lineName, lineLogo, newLineLogo, maxPeople}, callback) {
        //return new Promise((resolve, reject) => {
            let geometry;
            if (location) {
                const newLocation = await this.getGPSByAddress(location);
                geometry = newLocation?.results[0]?.geometry?.location;
            }
            let lineLogoUrl;
            if (newLineLogo) {
                lineLogoUrl = await this.getLogoLink(userId, newLineLogo);
            }
                    const thePath = `users/${userType}/${userId}/line`;
                    const genRef = this.database.ref(thePath);
                    genRef
                        .update({
                            capacity,
                            enterTime,
                            location,
                            gps: geometry || {},
                            radius,
                            lineName,
                            lineLogo: lineLogoUrl || lineLogo || '',
                            maxPeople
                        }, error => {
                        if (error) {
                            return error;
                        } else {
                            genRef.once('value', snapshot => {
                                const line = snapshot.val();
                                if (line) {
                                    if (line.status === 'active' || line.status === 'freez') {
                                        this.copyLine(userId, line).then(res => {
                                            callback({line, res});
                                        });
                                    } else {
                                        callback({line, res: {type: 'success', message: 'Line updated.'}});
                                    }
                                }
                            }, error => error)
                        }
                    })
                        .then(e => ({done: true}));
           //     });
           // });
        //});
    };

    onInviteScan = ({providerId, userId}) => {
        return new Promise((resolve, reject) => {
            const thePath = `lines/${providerId}/invitations/${userId}`;
            const genRef = this.database.ref(thePath);
            genRef
                .update({
                    scanned: true
                }, error => {
                    if (error) {
                        reject(error);
                    }

                })
                .then(e => {
                    const theMemberPath = `lines/${providerId}/members/${userId}/invitation`;
                    const genRef = this.database.ref(theMemberPath);
                    genRef
                        .update({
                            scanned: true
                        }, error => {
                            if (error) {
                                reject(error);
                            }

                        })
                        .then(e => {
                            resolve({done: true})
                        });
                });
        });
    };

    onLineStatusUpdate = ({userId, userType, status}, callback) => {
        return new Promise((resolve, reject) => {
            const thePath = `users/${userType}/${userId}/line`;
            const genRef = this.database.ref(thePath);
            genRef.once('value', snapshot => {
                const line = snapshot.val();
                if (line) {

                    let maybeStatus = undefined
                    if (status === 'freez' && line.status === 'freez') {
                        maybeStatus = 'active'
                    }
                    genRef
                        .update({
                            status: maybeStatus || status
                        }, error => {
                            if (error) {
                                reject(error);
                            } else {
                                genRef.once('value', snapshot => {
                                    const line = snapshot.val();
                                    if (line) {
                                        if (line.status === 'active' || line.status === 'freez') {
                                            this.copyLine(userId, line).then(res => {
                                                callback({line, res});
                                            });
                                        } else {
                                            this.removeLine(userId).then(res => {
                                                callback({line, res});
                                            });
                                        }
                                    }
                                }, error => reject(error))
                            }
                        })
                        .then(e => resolve({done: true}));
                }
            }, error => reject(error))

        });

    };

    onSendInvitation = ({ userId }) => {
        return new Promise((resolve, reject) => {
            const thePath = `lines/${userId}`;
            const genRef = this.database.ref(thePath);
            genRef.once('value', snapshot => {
                const line = snapshot.val();
                if (line) {
                    const enterTime = line.enterTime;
                    const maxPeople = line.maxPeople;
                    const maxInvitations = Math.round((line.capacity - line.slots) / maxPeople)
                    // get all members in array, sort them by joinedOn smallest goes first, pick first maxInvitations
                    let invitationsSent = 0;
                    if (line.members) {
                        const memberArr = Object.keys(line.members).map(key => {
                            if (!line.members[key].invitation) {
                                return {id: key, ...line.members[key]}
                            }
                        });
                        memberArr.sort((a, b) => {
                            if (a.joinedOn > b.joinedOn) {
                                return 1
                            } else if (a.joinedOn < b.joinedOn) {
                                return -1
                            } else {
                                return 0
                            }
                        });
                        while (invitationsSent < maxInvitations && memberArr[invitationsSent]) {

                            const sentOn = moment().format('x');
                            const expiredOn = moment().add(enterTime, 'minutes').format('x');
                            const invitationId = memberArr[invitationsSent].id;
                            const invitesPath = `lines/${userId}/invitations/${invitationId}`;
                            const invitationRef = this.database.ref(invitesPath);
                            const nextInvitation = {
                                id: uuidv4(),
                                userId: memberArr[invitationsSent].id,
                                sentOn,
                                expiredOn,
                                scanned: false,
                                viewed: false,
                            };
                            invitationRef.set(nextInvitation, error => {
                                if (error) {
                                    reject(error);
                                }
                                const memberPath = `lines/${userId}/members/${invitationId}`;
                                const memberRef = this.database.ref(memberPath);
                                memberRef.update(
                                    {
                                        invitation: nextInvitation
                                    }, error => {
                                        if (error) {
                                            reject(error);
                                        }
                                    });
                            });
                            invitationsSent += 1;

                        };
                        resolve({done: true});
                    }
                }

            });
        });
    };

    checkInvitation = ({userId, lineId}, callback) => {
        return new Promise((resolve, reject) => {
            if (!userId) {
                reject('No uid');
            }
            if (!lineId) {
                reject('No line');
            }
            const theInvitationPath = `lines/${lineId}/invitations/${userId}`;
            const genRef = this.database.ref(theInvitationPath);

            genRef.on('value', snapshot => {
                const inviteObj = snapshot.val();
                if (inviteObj) {
                    callback({inviteObj, res: {type: 'success', message: 'Got invitation.'}});
                    resolve({done: true});
                }
            }, error => reject(error))
        });
    };

    loadInvitations = ({lineId}, callback) => {
        return new Promise((resolve, reject) => {
            if (!lineId) {
                reject('No line');
            }
            const theInvitationPath = `lines/${lineId}/invitations`;
            const genRef = this.database.ref(theInvitationPath);

            genRef.on('value', snapshot => {
                const invitations = snapshot.val();
                if (invitations) {
                    const toArr = Object.keys(invitations).map(key => ({...invitations[key]}));
                    // callback({invitations: toArr, res: {type: 'success', message: 'Got invitations.'}});
                    callback(toArr);
                    resolve({done: true});
                }
            }, error => reject(error))
        });
    }


    checkUserInvitation = ({userId, lineId}, callback) => {
        return new Promise((resolve, reject) => {
            if (!userId) {
                reject('No uid');
            }
            if (!lineId) {
                reject('No line');
            }
            const theInvitationPath = `lines/${lineId}/members/${userId}/invitation`;
            const genRef = this.database.ref(theInvitationPath);

            genRef.on('value', snapshot => {
                const inviteObj = snapshot.val();
                if (inviteObj) {
                    callback({inviteObj, res: {type: 'success', message: 'Got invitation.'}});
                    resolve({done: true});
                }
            }, error => reject(error))
        });
    };

    copyLine = (userId, line) => {
        return new Promise((resolve, reject) => {
            const thePath = `lines/${userId}`;
            const genRef = this.database.ref(thePath);
            genRef
                .update({
                ...line
            }, error => {
                if (error) {
                    reject({type: 'error', message: `Error occurred: ${error}`});
                }
            })
                .then(e => resolve({type: 'success', message: 'Line copied.'}));
        });
    };

    removeLine = (userId) => {
        return new Promise((resolve, reject) => {
            const thePath = `lines/${userId}`;
            const genRef = this.database.ref(thePath);
            genRef
                .set(null, error => {
                    if (error) {
                        reject({type: 'error', message: `Error occurred: ${error}`});
                    }
                })
                .then(e => resolve({type: 'success', message: 'Line removed.'}));
        });
    };

    onAddCustomer = ({userId, userType, value}, callback) => {
        return new Promise((resolve, reject) => {
            const thePath = `users/${userType}/${userId}/line`;
            const genRef = this.database.ref(thePath);
            let slots = 0;
            let capacity = 0;
            genRef.once('value', snapshot => {
                const line = snapshot.val();
                if (line) {
                    slots = line.slots || 0;
                    capacity = line.capacity;
                    const maybeSlots = slots + value;
                    if (maybeSlots <= capacity) {
                        genRef
                            .update({
                                slots: maybeSlots
                            }, error => {
                                if (error) {
                                    reject({type: 'error', message: 'Error occurred: no capacity'});
                                } else {
                                    genRef.once('value', snapshot => {
                                        const line = snapshot.val();
                                        if (line) {
                                            this.copyLine(userId, line).then(res => {
                                                callback({line, res});
                                            });
                                        }
                                    }, error => reject({type: 'error', message: `Error occurred: ${error}`}))
                                }
                            })
                            .then(e => resolve({done: true}));
                    }
                }
            }, error => reject(error))

        });
    };

    onAddSlot = ({userId, userType, value}, callback) => {
        return new Promise((resolve, reject) => {
            const thePath = `users/${userType}/${userId}/line`;
            const genRef = this.database.ref(thePath);
            let slots = 0;
            let capacity = 0;
            genRef.once('value', snapshot => {
                const line = snapshot.val();
                if (line) {
                    slots = line.slots || 0;
                    capacity = line.capacity;
                    const maybeSlots = slots - value;
                    if (maybeSlots >= 0) {
                        genRef
                            .update({
                                slots: maybeSlots
                            }, error => {
                                if (error) {
                                    reject('Go to negative slots');
                                } else {
                                    genRef.once('value', snapshot => {
                                        const line = snapshot.val();
                                        if (line) {
                                            this.copyLine(userId, line).then(res => {
                                                callback({line, res});
                                            });
                                        }
                                    }, error => reject(error))
                                }
                            })
                            .then(e => resolve({done: true}));
                    }
                }
            }, error => reject(error))

        });
    };

    requestDeleteSubscription = (userId, subId) => {
        return new Promise((resolve, reject) => {
            const thePath = `deleteSubs/${userId}/`;
            const genRef = this.database.ref(thePath);
            genRef
                .set({
                    subId,
                }, error => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve({done: true});
                    }
                });
        });
    };

    loadUserProfile = (uid, type) => {
        return new Promise((resolve, reject) => {
            if (!uid) {
                reject('No uid');
            }
            if (!type) {
                reject('No type');
            }
            const thePath = `users/${type}/${uid}`;
            const genRef = this.database.ref(thePath);

            genRef.once('value', snapshot => {
                const userObj = snapshot.val();
                if (userObj) {
                    if (userObj.userLines) {
                        userObj.userLines = Object.keys(userObj.userLines).map(key =>
                            ({id: key, ...userObj.userLines[key]}));
                    }
                    resolve(userObj);
                }
            }, error => reject(error))
        });
    };

    loadLines = (callBack) => {
        return new Promise((resolve, reject) => {
            const thePath = `lines`;
            const genRef = this.database.ref(thePath);
            genRef.on('value', snapshot => {
                const linesObj = snapshot.val();
                if (linesObj) {
                    const resArr = [];
                    Object.keys(linesObj).forEach(key => {
                        const theLine = {id: key, ...linesObj[key]}
                        if (theLine.members) {
                            theLine.members = Object.keys(theLine.members).map(key =>
                                ({id: key, ...theLine.members[key]}));
                        }
                        resArr.push(theLine)
                    });
                    callBack(resArr);
                    resolve({done: true});
                }
            }, error => reject(error))
        });
    };

    joinLine = ({uid, userLocation, lineId, lineLocation}, callBack) => {
        return new Promise((resolve, reject) => {
            if (!uid) {
                reject('No uid');
            }
            if (!userLocation) {
                reject('No user location');
            }
            if (!lineId) {
                reject('No line Id');
            }
            if (!lineLocation) {
                reject('No line location');
            }
            const theLinePath = `lines/${lineId}`;
            const genRef = this.database.ref(theLinePath);
            genRef.once('value', snapshot => {
                const line = snapshot.val();
                if (line && line.status === 'active') {
                    const memberPath = `${theLinePath}/members/${uid}`;
                    const memberRef = this.database.ref(memberPath);
                    memberRef.set({
                        joinedOn: Date.now(),
                        fromLocation: userLocation,
                    }, error => {
                        if (error) {
                            reject(error);
                        } else {
                            const userPath = `users/customer/${uid}/userLines/${lineId}`;
                            const userRef = this.database.ref(userPath);
                            userRef.update({
                                joinedOn: Date.now(),
                                fromLocation: userLocation,
                            }, error => {
                                if (error) {
                                    reject({type: 'error', message: 'Cannot update user account.'});
                                }})
                            .then(e => {
                                const userLinePath = `users/customer/${uid}/userLines`;
                                const userLineRef = this.database.ref(userLinePath);
                                userLineRef.once('value', snapshot => {
                                    const lines = snapshot.val();
                                    const liensArr = [];
                                    Object.keys(lines).forEach(line => liensArr.push({id: line, ...lines[line]}));
                                    callBack({line: liensArr, res: {type: 'success', message: 'Joined line.'}});
                                    resolve({done: true});
                                    }, error => reject(error));
                                }
                            );
                        }
                    });
                } else {
                    reject('Line is not active');
                }
            });
        });
    };
    leaveLine = ({uid, userLocation, lineId}, callBack) => {
        return new Promise((resolve, reject) => {
            if (!uid) {
                reject('No uid');
            }
            if (!userLocation) {
                reject('No user location');
            }
            if (!lineId) {
                reject('No line Id');
            }

            const theLinePath = `lines/${lineId}`;
            const genRef = this.database.ref(theLinePath);
            genRef.once('value', snapshot => {
                const line = snapshot.val();
                if (line && line.status === 'active') {
                    const memberPath = `${theLinePath}/members/${uid}`;
                    const memberRef = this.database.ref(memberPath);
                    memberRef.set(null, error => {
                        if (error) {
                            reject(error);
                        } else {
                            const userPath = `users/customer/${uid}/userLines/${lineId}`;
                            const userRef = this.database.ref(userPath);
                            userRef.set(null, error => {
                                if (error) {
                                    reject({type: 'error', message: 'Cannot update user account.'});
                                }})
                            .then(e => {
                                const userLinePath = `users/customer/${uid}/userLines`;
                                const userLineRef = this.database.ref(userLinePath);
                                userLineRef.once('value', snapshot => {
                                    const lines = snapshot.val();
                                    const liensArr = [];
                                    if (lines) {
                                        Object.keys(lines).forEach(line => liensArr.push({id: line, ...lines[line]}));
                                    }
                                    callBack({line: liensArr, res: {type: 'success', message: 'Left line.'}});
                                    resolve({done: true});
                                    }, error => reject(error));
                                }
                            );
                        }
                    });
                } else {
                    reject('Line is not active');
                }
            });
        });
    };

    loadUserProfileOld = (uid, callBack) => {
        if (!uid) {
            return
        }
        const user = this.auth.currentUser;
        user.getIdTokenResult()
            .then((idTokenResult) => {
                // Confirm the user is an Admin.
                //console.log('user', idTokenResult)
            })
            .catch((error) => {
                console.log(error);
            });
        const thePath = `user/${uid}/`;
        const genRef = this.database.ref(thePath);
        genRef.once('value', snapshot => {
            const userObj = snapshot.val();
            if (userObj) {
                callBack(userObj);
            }
        })
    };

    logCheckUser = ({ uid }, address) => {
        const streetName = address.streetName.trim().toUpperCase().replace(/[\s]+/g, '_');
        const streetNumber = address.streetNumber.trim();
        if (!uid) {
            return
        }
        // check if there is a history at all
        const historyPath = `user/${uid}/history/`;
        const histRef = this.database.ref(historyPath);
        const theAddressPath = `user/${uid}/history/${streetName}/${streetNumber}`;
        return new Promise((resolve, reject) => {
            histRef.once('value', snapshot => {
                if (snapshot.exists()) {
                    // check whether a user has the address in the history or has a paid subscription
                    const genRef = this.database.ref(theAddressPath);
                    genRef.once('value', snapshot => {
                        if (snapshot.exists()) {
                            resolve({hasAccess: true});
                        } else {
                            // check if a user has a paid access
                            const userPaymentsPath = `user/${uid}/payments/`;
                            const paymentsRef = this.database.ref(userPaymentsPath);
                            paymentsRef.once('value', snapshot => {
                                if (snapshot.exists()) {
                                    // check payments
                                    snapshot.forEach(childSnapshot => {
                                        const payment = childSnapshot.val();
                                        const paymentExpired = moment(payment.boughtOn, 'X').add(8, 'days').format('X');
                                        const currentDate = moment().format('X')
                                        if (paymentExpired >= currentDate) {
                                            resolve({hasAccess: true});
                                            const genRef = this.database.ref(theAddressPath);
                                            genRef.set({
                                                accessed: currentDate
                                            })
                                        }
                                    });
                                    resolve({hasAccess: false});
                                } else {
                                    resolve({hasAccess: false});
                                }
                            })
                        }
                    })
                } else {
                    // allow a user to get one address
                    const genRef = this.database.ref(theAddressPath);
                    genRef.set({
                        accessed: moment().format('X')
                    }, error => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve({hasAccess: true});
                        }
                    });
                }
            });
        });
    };

    loadAddress = (userID, address, callBack) => {
        if (!address.streetName || !address.streetNumber) {
            callBack([]);
            return
        }
        const streetName = address.streetName.trim().toUpperCase().replace(/[\s]+/g, '_');
        const streetNumber = address.streetNumber.trim();

        let databaseRef = this.database.ref(`winnipeg/sales/by_streets/${streetName}/`);
        databaseRef.once('value', snapshot => {
            const sales = [];
            snapshot.forEach(childSnapshot => {
                const houseObj = childSnapshot.val()[streetNumber];
                if (houseObj) {
                    const years = Object.keys(houseObj)
                    for(const year of years) {
                        const yearObj = houseObj[year];
                        const months = Object.keys(yearObj)
                        for(const month of months) {
                            const sale = yearObj[month];
                            const house = {
                                year,
                                month,
                                ...sale
                            };
                            sales.push(house);
                        }
                    }

                }
            });
            let dbPermitsRef = this.database.ref(`winnipeg/streets/permits/by_streets/${streetName}/`);
            const permits = [];
            dbPermitsRef.once('value', snapshot => {
                snapshot.forEach(childSnapshot => {
                    const permitObj = childSnapshot.val()[streetNumber];
                    if (permitObj) {
                        permits.push(permitObj);
                    }
                });
                let dbAssessmentRef = this.database.ref(`winnipeg/streets/assessments/by_streets/${streetName}/`);
                const assets = [];
                dbAssessmentRef.once('value', snapshot => {
                    snapshot.forEach(childSnapshot => {
                        const houseObj = childSnapshot.val()[streetNumber];
                        if (houseObj) {
                            assets.push(houseObj)
                        }

                    });
                    let dbAddressRef = this.database.ref(`winnipeg/streets/addresses/by_streets/${streetName}/`);
                    let addrObj = {}
                    dbAddressRef.once('value', snapshot => {
                        snapshot.forEach(childSnapshot => {
                            const houseObjArray = childSnapshot.val();
                            let tempStreetNumber = 0;
                            let houseObj;
                            if (!childSnapshot.val()[streetNumber]) {
                                for (const el in houseObjArray) {
                                    if (el > tempStreetNumber && el < streetNumber) {
                                        houseObj = houseObjArray[el]
                                    }
                                    tempStreetNumber = el;
                                }
                            } else {
                                houseObj = childSnapshot.val()[streetNumber];
                            }
                            if (houseObj) {
                                addrObj.neighbourhood = houseObj.neighbourhood;
                                addrObj.schoolDivision = houseObj.schoolDivision;
                                addrObj.ward = houseObj.ward;
                                addrObj.schoolDivisionWard = houseObj.schoolDivisionWard;
                            }
                        });
                        const ward = addrObj.ward && addrObj.ward.toUpperCase().replace(/[.\s]+/g, '_');
                        const neighbourhood = addrObj.neighbourhood && addrObj.neighbourhood.toUpperCase().replace(/[.\s]+/g, '_');
                        let dbCityServiceRef = this.database.ref(`winnipeg/streets/city_service/by_wards/${ward}/${neighbourhood}/`);
                        let serviceRequests = []
                        dbCityServiceRef.once('value', snapshot => {
                            snapshot.forEach(childSnapshot => {
                                serviceRequests.push(childSnapshot.val());


                            });
                            let millRate = {};
                            let schoolDivision = addrObj.schoolDivision && addrObj.schoolDivision.toUpperCase().replace(/[.\s]+/g, '_');
                            if (addrObj.schoolDivision) {
                                let dbMillRateRef = this.database.ref(`winnipeg/streets/mill_rates/${schoolDivision}/`);
                                dbMillRateRef.once('value', snapshot => {
                                    millRate = snapshot.val();
                                    const house = Object.assign({}, assets[0], {sales: sales}, {permits}, {address: addrObj}, {serviceRequests: serviceRequests}, {millRate});
                                    callBack(house);
                                });
                            } else {
                                    const house = Object.assign({}, assets[0], {sales: sales}, {permits}, {address: addrObj}, {serviceRequests: serviceRequests}, {millRate});
                                    callBack(house);
                            }

                        });

                    });

                });
            });
        })
    };
    loadStreet = (userID, {streetName, streetType, streetDirection}, callBack) => {
        if (!streetName || !streetType) {
            return
        }
        const streetRequest = streetName.toUpperCase().replace(/[\s]+/g, '_');
        // find street type match
        let setType;
        for(const sType of STREET_TYPES_2) {
            if (sType.includes(streetType)) {
                setType = sType.slice();
            }
        }
        let databaseRef = this.database.ref(`winnipeg/sales/by_streets/${streetRequest}/`);
        databaseRef.once('value', snapshot => {
            const sales = [];
            snapshot.forEach(childSnapshot => {
                if (setType.includes(childSnapshot.key)) {
                    const obj = childSnapshot.val();
                    const numbers = Object.keys(obj);
                    for (const number of numbers) {
                        const salesObj = obj[number]
                        const years = Object.keys(salesObj)
                        for(const year of years) {
                            const yearObj = salesObj[year];
                            const months = Object.keys(yearObj)
                            for(const month of months) {
                                const sale = yearObj[month];
                                const house = {
                                    streetNumber: parseInt(number, 10),
                                    year,
                                    month,
                                    ...sale
                                };
                                sales.push(house);
                            }
                        }

                    }
                }
            });

            let dbAssessmentRef = this.database.ref(`winnipeg/streets/assessments/by_streets/${streetName}/`);
            const assessments = [];
            const salesWithAssets = [];
            dbAssessmentRef.once('value', snapshot => {
                snapshot.forEach(childSnapshot => {
                    if (setType.includes(childSnapshot.key)) {

                        const assetSet = childSnapshot.val();
                        const numbers = Object.keys(assetSet);
                        for(const number of numbers) {
                            const house = {
                                number: parseInt(number, 10),
                                ...assetSet[number]
                            };
                            let saleHouses = sales.filter(el => el.streetNumber === house.number);
                            if (saleHouses.length > 0) {
                                saleHouses.forEach(saleHouse => {
                                    assetSet[number].streetNumber = parseInt(assetSet[number].streetNumber, 10)
                                    saleHouse = Object.assign({}, saleHouse, assetSet[number]);
                                    saleHouse.price = Number(saleHouse.price);
                                    saleHouse.livingArea = Number(saleHouse.livingArea);
                                    saleHouse.priceAreaRatio = saleHouse.price / saleHouse.livingArea;
                                    saleHouse.priceAreaRatio = parseFloat(saleHouse.priceAreaRatio).toFixed(2);

                                    salesWithAssets.push(saleHouse);
                                })
                            }
                            assessments.push(house);
                        }
                    }
                });

                callBack({sales: salesWithAssets, assessments});
            });

        })
    };
}

export default Firebase;
