import React from 'react';
import Signout from '../Auth/Signout';

import  { FirebaseContext } from '../Firebase';

const Logout = (props) => (
    <FirebaseContext.Consumer>
        {firebase => (
            <div>
                <Signout {...props} firebase={firebase}/>
            </div>)
        }
    </FirebaseContext.Consumer>
);

export default Logout;
