import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    alert: {
        color: red[300]
    },
    registerArea: {
        marginTop: 30,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center'
    },
    registerButton: {
        textAlign: 'center'
    }
});

const LineStatus = (props) => {
    const { spots, classes, status, slotsUsed, slotsAvailable, onSendInvitation } = props;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Line Status</Typography>
                <div className={classes.column}>
                    <Typography>Customers in Line</Typography>
                    <Typography>{spots}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography>Slots Available</Typography>
                    <Typography>{slotsAvailable}</Typography>
                    <Button
                        onClick={() => onSendInvitation({customersNum: undefined}) /**/}
                        color="secondary"
                        variant="contained"
                        disabled={slotsAvailable === 0}
                        className={classes.registerButton}
                    >
                        Invite Customers
                    </Button>
                </div>
                <div className={classes.column}>
                    <Typography>Slots Used</Typography>
                    <Typography>{slotsUsed}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography>Line Status</Typography>
                    <Typography>{status}</Typography>
                </div>
            </Paper>
        </div>

    )
};

LineStatus.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    tabsChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(LineStatus);
