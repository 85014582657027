import React from 'react'
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
});

const Payment = (props) => {
    const { classes, payment } = props;
    return(
        <Grid
            direction="column"
            justify="flex-start"
            container
            spacing={1}
        >

        {payment && payment.display_items && payment.display_items.map(item => {
            return(
                <Grid item xs={12} stretch>
                    <Paper className={classes.paper}>
                        <Grid
                            direction="row"
                            justify="center"
                            container
                            spacing={3}
                        >
                            <Grid item xs={4} stretch>
                                <Typography variant="h6">
                                    Product
                                </Typography>
                                <Typography variant="body1">
                                    {item.sku.attributes.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} stretch>
                                <Typography variant="h6">
                                    Paid
                                </Typography>
                                <Typography variant="body1">
                                    {item.amount / 100} in {item.currency}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} stretch>
                                <Typography variant="h6">
                                    Bought on
                                </Typography>
                                <Typography variant="body1">
                                    {moment(payment.boughtOn, 'X').format('MMM-DD-YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} stretch>
                                <Typography variant="h6">
                                    Expired on
                                </Typography>
                                <Typography variant="body1">
                                    {moment(payment.boughtOn, 'X').add(8, 'days').format('MMM-DD-YYYY')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )
        })}
        </Grid>
    )
}

export default withStyles(styles)(Payment)
