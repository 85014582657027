import React from 'react';
import Subscription from '../Auth/Subscription';
import {StripeProvider} from 'react-stripe-elements';

import  { FirebaseContext } from '../Firebase';

const SubscriptionView = (props) => {
    return (
    <FirebaseContext.Consumer>
        {firebase => (
            <StripeProvider apiKey="pk_test_myBuQU3JQgQgPbS8dbSflLpd00MjKnVYhc">
                <Subscription {...props} firebase={firebase}/>
            </StripeProvider>)
        }
    </FirebaseContext.Consumer>
)};

export default SubscriptionView;
