import React from 'react';
import {Typography} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 90,
    },
});

const NoMatch = (props) =>
    <div className={props.classes.root}>
        <Typography variant='h4'>No Such Page. Sorry...</Typography>
    </div>;

export default  withStyles(styles)(NoMatch);